/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import {
  appId,
  apiUrl,
  cateringId,
  apiUrlV2,
  CountryTxt,
  stripeImage,
  stripeCurrency,
  stripeReference,
} from "../../Helpers/Config";
import {
  getReferenceID,
  showLoader,
  hideLoader,
  showAlert,
  timeToConv12,
  dateConvFun,
  showCartItemCount,
  showCustomAlert,
  getGstValue,
  stripslashes,
  getPromoCkValue,
  removePromoCkValue,
  smoothScroll,
  showPriceValue,
} from "../../Helpers/SettingHelper";
import cookie from "react-cookies";
import axios from "axios";
var qs = require("qs");
var Parser = require("html-react-parser");

import { GET_CATERINGCART_DETAIL, GET_STATIC_BLOCK } from "../../../actions";

import Cartdetails from "./Cartdetails";
import Packageupdate from "./Packageupdate";
import CantDlyImg from "../../../common/images/Cant_Deliver.png";
import loadingImage from "../../../common/images/loading_popup.gif";
import tickImage from "../../../common/images/tick_popup.png";
import paymentImage from "../../../common/images/payment.png";

class Checkout extends Component {
  constructor(props) {
    super(props);

    var getPromoCkVal = getPromoCkValue();

    var servicesurchargeArr = Array();
    servicesurchargeArr["servicechrg_displaylabel"] = "Service Charge";
    servicesurchargeArr["servicechrg_type"] = "percentage";
    servicesurchargeArr["servicechrg_per"] = 0;
    servicesurchargeArr["servicechrg_amount"] = 0;
    servicesurchargeArr["surcharge_amount"] = 0;
    servicesurchargeArr["surcharge_type"] = "";
    servicesurchargeArr["surcharge_date"] = "";
    servicesurchargeArr["surcharge_frmtime"] = "";
    servicesurchargeArr["surcharge_totime"] = "";

    this.state = {
      settings: [],
      staticblacks: [],
      catering_postal_code: "",
      catering_address_line: "",
      catering_address_line2: "",
      catering_floor_no: "",
      catering_unit_no: "",
      billing_addrs_sameas: "yes",
      billing_postal_code: "",
      billing_address_line: "",
      billing_floor_no: "",
      billing_unit_no: "",
      catering_specil_note: "",
      breaktime_count: 0,
      breaktime_enable: "",
      breaktime_list: [],
      edite_packageid: "",
      cart_packagedata: [],
      cart_maindata: [],
      edit_flag: 0,
      promotion_count: 0,
      reward_point_count: 0,
      promotion_applied: getPromoCkVal["promotionApplied"],
      promotion_type: getPromoCkVal["promotionType"],
      promotion_amount: getPromoCkVal["promotionAmount"],
      promotion_source: getPromoCkVal["promotionSource"],
      promoIs_delivery: getPromoCkVal["promoIsDelivery"],
      reward_point_val: getPromoCkVal["reedemPointVal"],
      promo_code_val: getPromoCkVal["promoCodeVal"],
      used_reward_point: getPromoCkVal["usedPoints"],
      cartDetailsData: [],
      cartItemsList: [],
      validateimage: loadingImage,
      placingorderimage: loadingImage,
      completingpaymentimage: loadingImage,
      finalCartDetails: [],
      finalcartItems: [],
      catering_tarms_contn: "No",
      zone_id: "",
      termsAndConditions: "",

      omise_payment_enable: 0,
      omise_log_id: "",
      omisecodeerror: "",
      omiseyearerror: "",
      omisemontherror: "",
      omisecardrror: "",
      omisenameerror: "",
      holdername: "",
      cardNumber: "",
      expiration_month: "",
      expiration_year: "",
      security_code: "",
      cardImage: "",
      payment_ref_id: "",
      omise_tokken_response: "",
      omise_tokken_card_id: "",
      omise_tokken_id: "",
      omise_payment_enable: "'",
      paymentmodevalue: "",
      loadCart: true,
      servicesurchargeval: servicesurchargeArr,
    };
  }

  componentDidMount() {
    const script = document.createElement("script");
    script.src = "https://cdn.omise.co/omise.js";
    script.async = true;
    document.body.appendChild(script);
    this.getCommonSetting("");
    this.loadCartcontent();

    var fromOtherPageFlg =
      typeof cookie.load("fromOtherPageFlg") === "undefined"
        ? ""
        : parseInt(cookie.load("fromOtherPageFlg"));
    if (fromOtherPageFlg != "") {
      cookie.remove("fromOtherPageFlg");
      let $_this = this;
      setTimeout(function () {
        $_this.props.setActiveTab(fromOtherPageFlg);
      }, 1000);
    }

    setTimeout(function () {
      if ($(".checkout-terms-and-condition").length > 0) {
        $(".checkout-terms-and-condition").mCustomScrollbar();
      }
    }, 400);

    if ($(".input-focus").length > 0) {
      $(".input-focus").focus(function () {
        $(this).parents(".focus-out").addClass("focused");
      });
      $(".input-focus").blur(function () {
        var inputValue = $(this).val();
        if (inputValue == "") {
          $(this).removeClass("filled");
          $(this).parents(".focus-out").removeClass("focused");
        } else {
          $(this).addClass("filled");
        }
      });
    }
  }

  componentWillReceiveProps(categoryProps) {
    if (Object.keys(categoryProps).length > 0) {
      var categoryStateArr = categoryProps.categoryState;
      for (var key in categoryStateArr) {
        this.state[key] = categoryStateArr[key];
      }
    }

    var trgLgin = categoryStateArr.trigger_checkout_submit;
    if (trgLgin === "Yes") {
      cookie.save("loginpopupTrigger", "fromchecatering");
      this.props.sateValChangefun("checkout_submit", "Ok");
      $.magnificPopup.open({
        items: {
          src: "#login-popup",
        },
        type: "inline",
      });
    }

    var userIdTxt =
      typeof cookie.load("UserId") === "undefined" ? "" : cookie.load("UserId");
    var ckeckOutVld = cookie.load("fromCkeckOutVld");
    var totalItems = cookie.load("cartTotalItems");

    if (userIdTxt !== "" && ckeckOutVld === "Yes" && parseInt(totalItems) > 0) {
      cookie.save("fromCkeckOutVld", "No");
      smoothScroll(100, 70);
      this.props.setActiveTab(4);
    }

    if (
      userIdTxt !== "" &&
      cookie.load("applyCatrngPromo") === "Yes" &&
      parseInt(totalItems) > 0
    ) {
      cookie.save("applyCatrngPromo", "No");
      this.props.setActiveTab(4);
    }

    if (this.state.active_tabflag === 4) {
      /*this.props.getCateringCartDetail();*/
      this.getServiceChargeAmt();
      this.loadRewardPoints();
      this.loadCartList();
    }

    /*if(this.state.active_tabflag === 4 && this.state.cartItemsList !== categoryProps.cateringCartItems) {
			this.setState({cartDetailsData : categoryProps.cateringCartDetails, cartItemsList: categoryProps.cateringCartItems});
		}*/

    if (this.state.add_new_item === "Yes") {
      this.removePointsAndPromo();
      this.props.sateValChangefun("add_new_item", "No");
    }

    var CheckoutFromCart = cookie.load("cateringCheckoutFromCart");
    if (CheckoutFromCart === "Yes") {
      cookie.remove("cateringCheckoutFromCart");
      let $_this = this;
      setTimeout(function () {
        $_this.props.setActiveTab(4);
      }, 300);
    }

    var fromVenueFlg = cookie.load("fromVenueFlg");
    if (fromVenueFlg === "Yes") {
      cookie.remove("fromVenueFlg");
      this.props.setActiveTab(2);
      /*let $_this = this;
			setTimeout(function () {
				$_this.props.setActiveTab(2);
			}, 200);*/
    }

    if (categoryProps.staticblack !== this.state.staticblacks) {
      var termsConditions = "";
      if (Object.keys(categoryProps.staticblack).length > 0) {
        categoryProps.staticblack.map((data, index) => {
          if (data.staticblocks_slug === "terms-and-conditions") {
            termsConditions = data.staticblocks_description;
            return "";
          }
        });
      }
      termsConditions =
        termsConditions !== "" ? Parser(termsConditions) : termsConditions;
      this.setState({
        staticblacks: categoryProps.staticblack,
        termsAndConditions: termsConditions,
      });

      setTimeout(function () {
        $(".checkout-terms-and-condition").mCustomScrollbar();
      }, 800);
    }

    setTimeout(function () {
      if ($(".checkout-terms-and-condition").length > 0) {
        $(".checkout-terms-and-condition").mCustomScrollbar();
      }
    }, 800);
  }

  getServiceChargeAmt() {
    var orderDate = cookie.load("cateringDate");
    var orderTime = cookie.load("cateringTime");
    var availabilityIdTxt = cookie.load("defaultAvilablityId");
    var orderOutletId = cookie.load("cateringOutletId");

    var servicesurchargeArr = this.state.servicesurchargeval;
    var online_servicecharge_type = servicesurchargeArr["servicechrg_type"];
    var onlineservice_displaylabel =
      servicesurchargeArr["servicechrg_displaylabel"];
    var online_servicecharge_per = 0,
      online_servicecharge_amount = 0,
      subchr_amount = 0;
    var subchr_type = "",
      subchr_date = "",
      subchr_frmtime = "",
      subchr_totime = "";

    var urlPrms =
      "&subchravailability=yes&availabilityid=" +
      availabilityIdTxt +
      "&outletid=" +
      orderOutletId +
      "&orderdate=" +
      orderDate +
      "&ordertime=" +
      orderTime +
      "&";
    var urlShringTxt =
      apiUrl + "outlets/outletsubchargenew?app_id=" + appId + urlPrms;
    axios.get(urlShringTxt).then((res) => {
      if (res.data.status === "ok") {
        online_servicecharge_type = res.data.onlineservice_type;
        online_servicecharge_per =
          res.data.online_service_charge !== "" &&
          res.data.onlineservice_type == "percentage"
            ? parseFloat(res.data.online_service_charge)
            : 0;
        online_servicecharge_amount =
          res.data.online_service_charge !== "" &&
          res.data.onlineservice_type == "fixed"
            ? parseFloat(res.data.online_service_charge)
            : 0;
        onlineservice_displaylabel = res.data.onlineservice_displaylbl;
        if (res.data.subcharge_apply === "Yes") {
          var subchr_data = res.data.result_set[0];
          subchr_amount =
            subchr_data.subchr_value !== ""
              ? parseFloat(subchr_data.subchr_value)
              : 0;
          subchr_type = subchr_data.subchr_type;
          if (subchr_data.subchr_type === "Time") {
            subchr_frmtime = this.tmConv24(subchr_data.subchr_from_time);
            subchr_totime = this.tmConv24(subchr_data.subchr_to_time);
          } else {
            subchr_date = subchr_data.subchr_date;
          }
        }
      }

      servicesurchargeArr["servicechrg_displaylabel"] =
        onlineservice_displaylabel;
      servicesurchargeArr["servicechrg_type"] = online_servicecharge_type;
      servicesurchargeArr["servicechrg_per"] = online_servicecharge_per;
      servicesurchargeArr["servicechrg_amount"] = online_servicecharge_amount;
      servicesurchargeArr["surcharge_amount"] = subchr_amount;
      servicesurchargeArr["surcharge_type"] = subchr_type;
      servicesurchargeArr["surcharge_date"] = subchr_date;
      servicesurchargeArr["surcharge_frmtime"] = subchr_frmtime;
      servicesurchargeArr["surcharge_totime"] = subchr_totime;
      this.setState(
        { servicesurchargeval: servicesurchargeArr },
        function () {
          this.postOrder("Yes", "Yes", "Yes");
        }.bind(this)
      );
    });
  }

  tmConv24(time24) {
    var ts = time24;
    if (ts !== "") {
      var H = +ts.substr(0, 2);
      var h = H % 12 || 12;
      h = h < 10 ? "0" + h : h;
      var ampm = H < 12 ? " AM" : " PM";
      ts = h + ts.substr(2, 3) + ampm;
    }
    return ts;
  }

  getCommonSetting(pstCode) {
    var pstCode = pstCode === "" ? this.state.catering_postal_code : "";
    var postCdVal = pstCode != "" ? "&postal_code=" + pstCode : "";
    axios
      .get(apiUrl + "settings/get_common_settings?app_id=" + appId + postCdVal)
      .then((res) => {
        if (res.data.status === "ok") {
          if (typeof res.data.result_set !== "undefined") {
            var tampStArr = res.data.result_set;
            var paymentmode = "Cash";
            var omisePayment = 0;
            if (Object.keys(tampStArr).length > 0) {
              if (
                tampStArr.client_omise_enable == 1 /*  &&
                tampStArr.client_omise_availability == 1 */
              ) {
                omisePayment = 1;
                if (paymentmode === "Cash") {
                  paymentmode = "Omise";
                }
              }
            }

            this.setState({
              settings: res.data.result_set,
              omise_payment_enable: omisePayment,
              paymentmodevalue: paymentmode,
            });
          }
        }
      });
  }

  loadCartList() {
    if (typeof cookie.load("UserId") === "undefined") {
      var customerParam = "&reference_id=" + cookie.load("referenceId");
    } else {
      var customerParam = "&customer_id=" + cookie.load("UserId");
    }

    axios
      .get(apiUrlV2 + "cateringcart/contents?app_id=" + appId + customerParam)
      .then((res) => {
        if (res.data.status === "ok") {
          var cartDetailsMn = res.data.result_set.cart_details;
          var cartItemsLst = res.data.result_set.cart_items;
        } else {
          var cartDetailsMn = [];
          var cartItemsLst = [];
        }

        this.setState({
          cartDetailsData: cartDetailsMn,
          cartItemsList: cartItemsLst,
        });
      });
  }

  loadRewardPoints() {
    var customerParam = "&status=A&customer_id=" + cookie.load("UserId");
    var actArr = [];
    actArr.push("promotionwithoutuqc");
    actArr.push("reward");
    var actArrParam = "&act_arr=" + JSON.stringify(actArr);

    axios
      .get(
        apiUrl +
          "reports/activity_counts1?app_id=" +
          appId +
          actArrParam +
          customerParam
      )
      .then((res) => {
        if (res.data.status === "ok") {
          var promoCount = res.data.result_set.promotionwithoutuqc;
          var rwdpointCnt = res.data.result_set.reward_ponits;
        } else {
          var promoCount = 0;
          var rwdpointCnt = 0;
        }

        this.setState({
          promotion_count: promoCount,
          reward_point_count: rwdpointCnt,
        });
      });
  }

  satePackChange = (field, value) => {
    if (field === "editpackage_deta") {
      var cartDataDetail = value;
      var CrdPkgData = cartDataDetail["cartLst"];
      var CrdMainData = cartDataDetail["cartMain"];
      if (Object.keys(CrdPkgData).length > 0) {
        var editePackageid = CrdPkgData.cart_item_product_id;
        var editFlag = CrdPkgData.cart_item_breaktime_indexflag;
        this.setState(
          {
            edite_packageid: editePackageid,
            edit_flag: editFlag,
            cart_packagedata: CrdPkgData,
            cart_maindata: CrdMainData,
          },
          function () {
            this.openPackagePopup();
          }.bind(this)
        );
      }
    }
  };

  openPackagePopup() {
    $("#PackageEditMdl").modal({ backdrop: "static", keyboard: false });
  }

  handlerTestFun() {}

  /* Validate floor && Unit No. */
  validatezip(e) {
    const re = /[0-9A-F:]+/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  }

  /* Validate Float Value */
  validateFloatval(e) {
    const re = /[0-9.]+/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  }

  /* Validate Int Value */
  validateIntval(e) {
    const re = /[0-9]+/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  }

  /* post code */
  changePostalCode(isFrm, event) {
    var postalCode = event.target.value;
    var postCdLth = postalCode !== "" ? postalCode.length : 0;
    var outletIdVl = cookie.load("cateringOutletId");
    if (isFrm === "cust") {
      $(".postal_code_cls").removeClass("err_alt");
      $(".cust_address_cls").removeClass("err_alt");
    }

    var isSame = this.state.billing_addrs_sameas;
    if (isFrm === "billing" || isSame === "yes") {
      $(".billing_postal_code_cls").removeClass("err_alt");
      $(".billing_address_cls").removeClass("err_alt");
    }

    if (postalCode !== "" && parseInt(postCdLth) === 6 && outletIdVl !== "") {
      /* zone based */
      var urlShringTxt =
        apiUrlV2 +
        "outlets/findOutletZone?app_id=" +
        appId +
        "&outlet_id=" +
        outletIdVl +
        "&availability_id=" +
        cateringId +
        "&postal_code=" +
        postalCode;

      /* with out zone */
      /*var urlShringTxt = apiUrl + 'outlets/find_outlet?app_id=' + appId +'&availability_id='+ cateringId+'&postal_code='+postalCode;*/

      axios.get(urlShringTxt).then((res) => {
        var custAddress = "";
        var zoneIdTxt = "";
        if (res.data.status === "ok") {
          var outltResulSet = res.data.result_set;
          if (Object.keys(outltResulSet.postal_code_information).length > 0) {
            zoneIdTxt = outltResulSet.zone_id;

            var outletAddress =
              outltResulSet.postal_code_information.zip_buno != ""
                ? outltResulSet.postal_code_information.zip_buno +
                  ", " +
                  outltResulSet.postal_code_information.zip_sname
                : outltResulSet.postal_code_information.zip_sname;

            custAddress = outletAddress + " " + CountryTxt;
          }
        }

        if (isFrm === "cust") {
          this.getCommonSetting(postalCode);
          this.setState(
            {
              catering_postal_code: postalCode,
              catering_address_line: custAddress,
              zone_id: zoneIdTxt,
            },
            function () {
              this.setBillingAddrs();
            }.bind(this)
          );
        } else if (isFrm === "billing") {
          this.setState({
            billing_postal_code: postalCode,
            billing_address_line: custAddress,
            zone_id: zoneIdTxt,
          });
        }
      });
    } else {
      var temparrs = "";
      if (isFrm === "cust") {
        $(".cust_address_cls").addClass("err_alt");
        this.setState(
          {
            catering_postal_code: postalCode,
            catering_address_line: temparrs,
            zone_id: temparrs,
          },
          function () {
            this.setBillingAddrs();
          }.bind(this)
        );
      } else if (isFrm === "billing") {
        this.setState({
          billing_postal_code: postalCode,
          billing_address_line: temparrs,
          zone_id: temparrs,
        });
      }

      if (isFrm === "billing" || isSame === "yes") {
        $(".billing_address_cls").addClass("err_alt");
      }
    }
  }

  CheckBillingAddrChk(type) {
    var isSame = this.state.billing_addrs_sameas;
    var chkBox = false;
    var diplayTxt = "block";
    if (isSame === "yes") {
      chkBox = true;
      diplayTxt = "none";
    }

    return type === "checkbox" ? chkBox : diplayTxt;
  }

  changeBillingAddrChk() {
    var isSame = this.state.billing_addrs_sameas;
    if (isSame === "yes") {
      var tempvlu = "";
      this.setState({
        billing_postal_code: tempvlu,
        billing_address_line: tempvlu,
        billing_floor_no: tempvlu,
        billing_unit_no: tempvlu,
        billing_addrs_sameas: "no",
      });
    } else {
      this.setState(
        { billing_addrs_sameas: "yes" },
        function () {
          this.setBillingAddrs();
        }.bind(this)
      );
    }
  }

  setBillingAddrs() {
    var isSame = this.state.billing_addrs_sameas;
    if (isSame === "yes") {
      var postalCode = this.state.catering_postal_code;
      var custAddress = this.state.catering_address_line;
      var floorNo = this.state.catering_floor_no;
      var unitNo = this.state.catering_unit_no;

      this.setState({
        billing_postal_code: postalCode,
        billing_address_line: custAddress,
        billing_floor_no: floorNo,
        billing_unit_no: unitNo,
      });
    }
    $(".cust_floor_no").removeClass("err_alt");
    $(".billing_floor_no").removeClass("err_alt");
  }

  handleAddrChange(event) {
    if (event.target.name === "address_line2") {
      this.setState({ catering_address_line2: event.target.value });
    } else if (event.target.name === "floor_no") {
      this.setState(
        { catering_floor_no: event.target.value },
        function () {
          this.setBillingAddrs();
        }.bind(this)
      );
    } else if (event.target.name === "unit_no") {
      this.setState(
        { catering_unit_no: event.target.value },
        function () {
          this.setBillingAddrs();
        }.bind(this)
      );
    } else if (event.target.name === "specil_note") {
      this.setState({ catering_specil_note: event.target.value });
    } else if (event.target.name === "reward_point") {
      this.setState({ reward_point_val: event.target.value });
    } else if (event.target.name === "promo_code") {
      this.setState({ promo_code_val: event.target.value });
    } else if (event.target.name === "bill_floor_no") {
      this.setState({ billing_floor_no: event.target.value });
      $(".billing_floor_no").removeClass("err_alt");
    } else if (event.target.name === "bill_unit_no") {
      this.setState({ billing_unit_no: event.target.value });
      $(".billing_floor_no").removeClass("err_alt");
    }
  }

  setPropActiveTab(tagvalue) {
    var tabflag = this.props.categoryState.active_tabflag;
    if (parseInt(tabflag) !== parseInt(tagvalue)) {
      this.props.setActiveTab(parseInt(tagvalue));
    } else {
      this.props.sateValChangefun("active_tabflag", 0);
    }
  }

  setDateTimeFun() {
    cookie.save("dateTimeTrigger", "yes");
    this.props.setActiveTab(2);
  }

  swtCartFun(stpflg, stpTxt) {
    $.magnificPopup.open({
      items: {
        src: "#swt_popup_id",
      },
      type: "inline",
    });
  }

  yesProcdFun() {
    this.cartDestoryFun();
    this.props.setActiveTab(2);
  }

  cartDestoryFun() {
    var postCrtObject = {};
    postCrtObject = {
      app_id: appId,
    };
    if (typeof cookie.load("UserId") === "undefined") {
      postCrtObject["reference_id"] = getReferenceID();
    } else {
      postCrtObject["customer_id"] = cookie.load("UserId");
    }

    axios
      .post(apiUrlV2 + "cateringcart/destroy", qs.stringify(postCrtObject))
      .then((res) => {
        if (res.data.status === "ok") {
          showCustomAlert("success", "Nice! Your cart is empty");
          $.magnificPopup.close();
          var cartDetails = Array();
          showCartItemCount(cartDetails);
          this.props.history.push("/catering");
        } else {
          $.magnificPopup.close();
          showCustomAlert("error", "Sorry! Products can`t update your cart");
        }
      })
      .catch(function (error) {
        console.log(error);
        showCustomAlert("error", "something went wrong");
        $.magnificPopup.close();
      });
  }

  applyPointsAndPromo(type) {
    var promotionApplied = this.state.promotion_applied;
    var promotionType = this.state.promotion_type;
    var promotionAmount = this.state.promotion_amount;
    var promotionSource = this.state.promotion_source;
    var promoIsDelivery = this.state.promoIs_delivery;
    var reedemPointVal = this.state.reward_point_val;
    var promoCodeVal = this.state.promo_code_val;
    var usedPoints = this.state.used_reward_point;

    /*var cartDetailsArr = (typeof cookie.load('cartDetail') !== 'undefined' && cookie.load('cartDetail') !== '') ? cookie.load('cartDetail') : Array();
		var cartItemsSateArr = (typeof cookie.load('cartItemsList') !== 'undefined' && cookie.load('cartItemsList') !== '') ? cookie.load('cartItemsList') : Array();*/

    var cartDetailsArr = this.state.cartDetailsData;
    var cartItemsSateArr = this.state.cartItemsList;

    var subTotal =
      Object.keys(cartDetailsArr).length > 0
        ? cartDetailsArr.cart_sub_total
        : 0;
    var totalItems =
      Object.keys(cartDetailsArr).length > 0
        ? cartDetailsArr.cart_total_items
        : 0;

    if (type === "points") {
      showLoader("applypoints_cls", "class");

      var postObject = {
        app_id: appId,
        reference_id: cookie.load("UserId"),
        redeem_point: reedemPointVal,
        cart_amount: subTotal,
      };

      axios
        .post(apiUrl + "loyalty/apply_loyalityv1", qs.stringify(postObject))
        .then((res) => {
          if (res.data.status === "success") {
            var pointDet = res.data.result_set;
            promotionApplied = "Yes";
            promotionType = "points";
            promotionAmount = pointDet.points_amount;
            promotionSource = reedemPointVal;
            promoIsDelivery = "";
            promoCodeVal = "";
            usedPoints = reedemPointVal;

            showCustomAlert(
              "success",
              "Point has been redeemed successfully!."
            );
            this.handleShowAlertFun(
              "success",
              "Point has been redeemed successfully!."
            );
          } else {
            if (promotionType === "points") {
              promotionApplied = "";
              promotionType = "";
              promotionAmount = "";
              promotionSource = "";
              promoIsDelivery = "";
              usedPoints = 0;
            }
          }

          hideLoader("applypoints_cls", "class");

          this.setState({
            reward_point_val: reedemPointVal,
            promo_code_val: promoCodeVal,
            promotion_applied: promotionApplied,
            promotion_type: promotionType,
            promotion_amount: promotionAmount,
            promotion_source: promotionSource,
            promoIs_delivery: promoIsDelivery,
            used_reward_point: usedPoints,
          });
        });
    } else if (type === "promoCode") {
      showLoader("applypromo_cls", "class");

      var categoryIdsDet = this.getProductIdsDet(cartItemsSateArr);

      var postObject = {
        app_id: appId,
        reference_id: cookie.load("UserId"),
        promo_code: promoCodeVal,
        cart_amount: subTotal,
        cart_quantity: totalItems,
        category_id: categoryIdsDet,
        availability_id: cateringId,
      };

      axios
        .post(
          apiUrl + "promotion_api_v2/apply_promotion",
          qs.stringify(postObject)
        )
        .then((res) => {
          if (res.data.status === "success") {
            var pointDet = res.data.result_set;
            var IsDelivery =
              pointDet.promotion_delivery_charge_applied == "Yes"
                ? "Yes"
                : "No";
            promotionApplied = "Yes";
            promotionType = "promoCode";
            promotionAmount = pointDet.promotion_amount;
            promotionSource = promoCodeVal;
            promoIsDelivery = IsDelivery;
            reedemPointVal = "";
            usedPoints = 0;

            showCustomAlert(
              "success",
              "Promotion has been applied successfully!."
            );
            this.handleShowAlertFun(
              "success",
              "Promotion has been applied successfully!."
            );
          } else {
            if (promotionType === "promoCode") {
              promotionApplied = "";
              promotionType = "";
              promotionAmount = "";
              promotionSource = "";
              promoIsDelivery = "";
            }
            var msgTxt = res.data.message
              ? res.data.message
              : "Sorry! Did not applied promo code";
            showCustomAlert("error", msgTxt);
          }

          hideLoader("applypromo_cls", "class");

          cookie.save("reedemPointVal", reedemPointVal);
          cookie.save("promoCodeVal", promoCodeVal);
          cookie.save("promotionApplied", promotionApplied);
          cookie.save("promotionType", promotionType);
          cookie.save("promotionAmount", promotionAmount);
          cookie.save("promotionSource", promotionSource);
          cookie.save("promoIsDelivery", promoIsDelivery);
          cookie.save("usedPoints", usedPoints);

          this.setState({
            reward_point_val: reedemPointVal,
            promo_code_val: promoCodeVal,
            promotion_applied: promotionApplied,
            promotion_type: promotionType,
            promotion_amount: promotionAmount,
            promotion_source: promotionSource,
            promoIs_delivery: promoIsDelivery,
            used_reward_point: usedPoints,
          });
        });
    }
  }

  getProductIdsDet(cartItems) {
    var product_cartid = "";
    if (Object.keys(cartItems).length > 0) {
      for (var key in cartItems) {
        if (
          product_cartid !== "" &&
          cartItems[key].cart_item_product_id !== ""
        ) {
          product_cartid += ";";
        }
        product_cartid +=
          cartItems[key].cart_item_product_id +
          "|" +
          cartItems[key].cart_item_total_price +
          "|" +
          cartItems[key].cart_item_qty;
      }
    }

    return product_cartid;
  }

  removePointsAndPromo() {
    removePromoCkValue();

    this.setState({
      reward_point_val: "",
      promo_code_val: "",
      promotion_applied: "",
      promotion_type: "",
      promotion_amount: "",
      promotion_source: "",
      promoIs_delivery: "",
      used_reward_point: 0,
    });
  }

  chkoutSateValChange = (field, value, value2) => {
    if (field === "cartLstData") {
      this.setState({ cartDetailsData: value, cartItemsList: value2 });
    } else if (field === "removePromo") {
      this.removePointsAndPromo();
    } else if (field === "loadCart") {
      this.setState({ loadCart: value });
    }
  };

  /* show online payment mode loading */
  onlinePaymentLoading() {
    return (
      <div className="process_col">
        <div className="process_left">
          <img src={this.state.validateimage} />
        </div>
        <div className="process_right">
          <h5>Processing</h5>
          <p>Validating card information.</p>
        </div>
      </div>
    );
  }
  /**/

  orderBCLoading() {
    return (
      <div className="process_col">
        <div className="process_left">
          <img src={this.state.placingorderimage} />
        </div>
        <div className="process_right">
          <h5>Processing</h5>
          <p>Placing your order now.</p>
        </div>
      </div>
    );
  }
  amountCaptureLoading() {
    return (
      <div className="process_col">
        <div className="process_left">
          <img src={this.state.completingpaymentimage} />
        </div>
        <div className="process_right">
          <h5>Processing</h5>
          <p>Completing your online payment.</p>
        </div>
      </div>
    );
  }

  /**/

  orderSubmition(validation_only = "No", captureID = "") {
    var errorFlg = "No";
    showLoader("checkout-continue-cls", "class");

    var eventType = cookie.load("catering_eventType");

    var postalCode = this.state.catering_postal_code;
    var addressLine = this.state.catering_address_line;
    var BillPostalCode = this.state.billing_postal_code;
    var BillAddressLine = this.state.billing_address_line;

    var unitnumber1 = this.state.catering_floor_no;
    var unitnumber2 = this.state.catering_unit_no;
    var BillingUnitNo1 = this.state.billing_floor_no;
    var BillingUnitNo2 = this.state.billing_unit_no;

    if (eventType !== "hall" && postalCode === "") {
      errorFlg = "Yes";
      $(".postal_code_cls").addClass("err_alt");
      window.scrollTo(0, 450);
      hideLoader("checkout-continue-cls", "class");
      return false;
    }

    if (eventType !== "hall" && addressLine === "") {
      errorFlg = "Yes";
      $(".cust_address_cls").addClass("err_alt");
      window.scrollTo(0, 300);
      hideLoader("checkout-continue-cls", "class");
      return false;
    }

    if (eventType !== "hall" && unitnumber1 === "" && unitnumber2 === "") {
      errorFlg = "Yes";
      $(".cust_floor_no").addClass("err_alt");
      window.scrollTo(0, 450);
      hideLoader("checkout-continue-cls", "class");
      return false;
    }

    if (eventType !== "hall" && BillPostalCode === "") {
      errorFlg = "Yes";
      $(".billing_postal_code_cls").addClass("err_alt");
      window.scrollTo(0, 650);
      hideLoader("checkout-continue-cls", "class");
      return false;
    }

    if (eventType !== "hall" && BillAddressLine === "") {
      errorFlg = "Yes";
      $(".billing_address_cls").addClass("err_alt");
      window.scrollTo(0, 500);
      hideLoader("checkout-continue-cls", "class");
      return false;
    }

    if (
      eventType !== "hall" &&
      BillingUnitNo1 === "" &&
      BillingUnitNo1 === ""
    ) {
      errorFlg = "Yes";
      $(".billing_floor_no").addClass("err_alt");
      window.scrollTo(0, 650);
      hideLoader("checkout-continue-cls", "class");
      return false;
    }

    if (
      this.state.catering_tarms_contn !== "Yes" &&
      this.state.termsAndConditions !== ""
    ) {
      errorFlg = "Yes";
      $(".tarms_chkbox_div").addClass("err_tarms_chk");
      window.scrollTo(0, 1000);
      hideLoader("checkout-continue-cls", "class");
      return false;
    }

    var cateringDate =
      typeof cookie.load("cateringDate") !== "undefined"
        ? cookie.load("cateringDate")
        : "";
    var cateringTime =
      typeof cookie.load("cateringTime") !== "undefined"
        ? cookie.load("cateringDate")
        : "";

    if (cateringDate === "") {
      errorFlg = "Yes";
      $(".catering_date_cls").addClass("err_alt");
      window.scrollTo(0, 600);
      hideLoader("checkout-continue-cls", "class");
      return false;
    }

    if (cateringTime === "") {
      errorFlg = "Yes";
      $(".catering_time_cls").addClass("err_alt");
      window.scrollTo(0, 600);
      hideLoader("checkout-continue-cls", "class");
      return false;
    }

    var userId =
      typeof cookie.load("UserId") !== "undefined" ? cookie.load("UserId") : "";
    if (userId === "") {
      errorFlg = "Yes";
      showCustomAlert(
        "error",
        "Sorry! User account has not valid, please login properly."
      );
      return false;
    }

    if (errorFlg === "No") {
      axios
        .get(
          apiUrlV2 +
            "cateringcart/contents?app_id=" +
            appId +
            "&customer_id=" +
            userId
        )
        .then((res) => {
          if (res.data.status === "ok") {
            var cartDetailsMn = res.data.result_set.cart_details;
            var cartItemsLst = res.data.result_set.cart_items;
          } else {
            var cartDetailsMn = [];
            var cartItemsLst = [];
          }
          this.setState(
            { finalCartDetails: cartDetailsMn, finalcartItems: cartItemsLst },
            function () {
              this.orderToPlace(validation_only, captureID);
            }.bind(this)
          );
        });
    }
  }

  loadCartcontent() {
    var userId =
      typeof cookie.load("UserId") !== "undefined" ? cookie.load("UserId") : "";
    if (userId !== "") {
      axios
        .get(
          apiUrlV2 +
            "cateringcart/contents?app_id=" +
            appId +
            "&customer_id=" +
            userId
        )
        .then((res) => {
          if (res.data.status === "ok") {
            var cartDetailsMn = res.data.result_set.cart_details;
            var cartItemsLst = res.data.result_set.cart_items;
          } else {
            var cartDetailsMn = [];
            var cartItemsLst = [];
          }
          this.setState({
            finalCartDetails: cartDetailsMn,
            finalcartItems: cartItemsLst,
          });
        })
        .catch((error) => {});
    }
  }

  orderToPlace(validation_only = "No", captureID = "") {
    var finalCartDetails = this.state.finalCartDetails;
    var finalcartItems = this.state.finalcartItems;

    if (
      Object.keys(finalCartDetails).length > 0 &&
      Object.keys(finalcartItems).length > 0
    ) {
      $.magnificPopup.open({
        items: {
          src: ".processing",
        },
        type: "inline",
        showCloseBtn: false,
        midClick: true,
        mainClass: "mfp-fade",
        closeOnBgClick: false,
      });

      this.postOrder("No", validation_only, captureID);
    } else {
      showCustomAlert("error", "Sorry! Your cart is empty.");
      hideLoader("checkout-continue-cls", "class");
    }
  }

  postOrder(intitalLoad = "No", validation_only = "No", captureID = "") {
    var validationOnly = validation_only;
    var finalcartItems = this.state.finalcartItems;

    var products = [];

    var row = this.state.finalcartItems.map(
      function (item, i) {
        var modifierdata = [];
        var addons = [];
        var addonsSetup = [];
        var equipments = [];
        var setupdata = [];

        var modifierLen = item.modifiers.length;
        if (modifierLen > 0) {
          for (var l = 0; l < modifierLen; l++) {
            var modifierVal = [];

            var modValuesArr = item.modifiers[l].modifiers_values;
            if (modValuesArr.length > 0) {
              for (var key in modValuesArr) {
                modifierVal.push({
                  modifier_value_id: modValuesArr[key].cart_modifier_id,
                  modifier_value_qty: modValuesArr[key].cart_modifier_qty,
                  modifier_value_name: stripslashes(
                    modValuesArr[key].cart_modifier_name
                  ),
                  modifier_value_price: modValuesArr[key].cart_modifier_price,
                });
              }
            }

            modifierdata.push({
              modifier_id: item.modifiers[l].cart_modifier_id,
              modifier_name: stripslashes(item.modifiers[l].cart_modifier_name),
              modifiers_values: modifierVal,
            });
          }
        }

        var addonsSetupLen = item.addons_setup.length;
        if (addonsSetupLen > 0) {
          for (var l = 0; l < addonsSetupLen; l++) {
            var tempArr = [];
            var addonsValueArr = item.addons_setup[l].addons_setup_values;
            for (var key in addonsValueArr) {
              tempArr.push({
                addon_setup_val_id: addonsValueArr[key].cart_addon_setup_val_id,
                addon_setup_val_title:
                  addonsValueArr[key].cart_addon_setup_val_title,
                addon_setup_val_desc:
                  addonsValueArr[key].cart_addon_setup_val_desc,
                addon_setup_val_price:
                  addonsValueArr[key].cart_addon_setup_val_price,
                addon_setup_val_qty:
                  addonsValueArr[key].cart_addon_setup_val_qty,
              });
            }

            addonsSetup.push({
              addon_setup_id: item.addons_setup[l].cart_addon_setup_id,
              addon_setup_title: item.addons_setup[l].cart_addon_setup_title,
              addon_setup_selection:
                item.addons_setup[l].cart_addon_setup_selection,
              addon_setup_amount: item.addons_setup[l].cat_addon_setup_amount,
              addons_setup_values: tempArr,
            });
          }
        }

        var setupLen = item.setup.length;
        if (setupLen > 0) {
          for (var l = 0; l < setupLen; l++) {
            setupdata.push({
              setup_p_id: item.setup[l].cart_setup_primary_key,
              setup_id: item.setup[l].cart_setup_id,
              setup_name: stripslashes(item.setup[l].cart_setup_name),
              setup_type: item.setup[l].cart_setup_type,
              setup_description: item.setup[l].cart_setup_description,
              setup_qty: item.setup[l].cart_setup_qty,
              setup_price: item.setup[l].cart_setup_price,
              setup_total_price: item.setup[l].cart_setup_tatalprice,
            });
          }
        }

        products.push({
          product_id: item.cart_item_product_id,
          product_name: stripslashes(item.cart_item_product_name),
          product_image: item.cart_item_product_image,
          product_specification: item.cart_item_special_notes,
          product_sku: stripslashes(item.cart_item_product_sku),
          product_qty: item.cart_item_qty,
          product_unit_price: item.cart_item_unit_price,
          product_total_amount: item.cart_item_total_price,
          modifiers: modifierdata,
          addons: addons,
          addons_setup: addonsSetup,
          equipments: equipments,
          setup: setupdata,
          breaktime_indexflag: item.cart_item_breaktime_indexflag,
          breaktime_started: item.cart_item_breaktime_started,
          breaktime_ended: item.cart_item_breaktime_ended,
        });

        return products;
      }.bind(this)
    );

    var eventType = cookie.load("catering_eventType");

    /* insert customner details */
    if (validationOnly !== "Yes") {
      var addressObject = {};
      var customerAddressId = 0;
      addressObject = {
        app_id: appId,
        customer_first_name: cookie.load("UserFname"),
        customer_last_name: cookie.load("UserLname"),
        customer_phone: cookie.load("UserMobile"),
        customer_email: cookie.load("UserEmail"),
        customer_address_line1: this.state.catering_address_line,
        customer_address_line2: this.state.catering_address_line2,
        customer_postal_code: this.state.catering_postal_code,
        customer_address_name: this.state.catering_floor_no,
        customer_address_name2: this.state.catering_unit_no,
        customer_status: "A",
        customer_order_status: "order",
        refrence: cookie.load("UserId"),
      };
      if (eventType !== "hall") {
        axios
          .post(
            apiUrl + "customer/secondary_address_add",
            qs.stringify(addressObject)
          )
          .then((res) => {
            if (res.data.status === "ok") {
              customerAddressId = res.data.insert_id;
            }
          });
      }
    }

    var cateringDateTxt = cookie.load("cateringDate");
    var cateringTimeTxt = cookie.load("cateringTime");
    var orderDate = cateringDateTxt + " " + cateringTimeTxt;

    var orderAddress = this.state.catering_address_line;
    var orderAddress2 = this.state.catering_address_line2;
    var orderPostalCode = this.state.catering_postal_code;
    var unitnumber1 = this.state.catering_floor_no;
    var unitnumber2 = this.state.catering_unit_no;

    var BillingAddress = this.state.billing_address_line;
    var BillingPostalCode = this.state.billing_postal_code;
    var BillingUnitNo1 = this.state.billing_floor_no;
    var BillingUnitNo2 = this.state.billing_unit_no;

    var postHall = [];
    if (eventType === "hall") {
      orderAddress = cookie.load("cateringHalladdressLine");
      orderPostalCode = cookie.load("cateringHallPostalcode");
      orderAddress2 = "";
      unitnumber1 = "";
      unitnumber2 = "";
      BillingAddress = "";
      BillingPostalCode = "";
      BillingUnitNo1 = "";
      BillingUnitNo2 = "";
      postHall.push({
        catering_venue_type: "catering_hall",
        catering_hall_id: cookie.load("cateringHallId"),
        hall_charges: cookie.load("cateringHallCharge"),
      });
    }

    var serviceSubChrArr = this.state.servicesurchargeval;
    var finalCartDetails = this.state.finalCartDetails;
    var cartSubTotal = finalCartDetails.cart_sub_total;
    var cartGrandTotal = finalCartDetails.cart_grand_total;

    var servicechargePer = 0;
    var serviceChrgAmt = 0;
    var surChargeAmt = 0;
    var servicechargeDisplaylabel = "";

    if (Object.keys(serviceSubChrArr).length > 0) {
      servicechargePer =
        serviceSubChrArr["servicechrg_per"] !== ""
          ? parseFloat(serviceSubChrArr["servicechrg_per"])
          : 0;
      var servicechrgAmunt =
        serviceSubChrArr["servicechrg_amount"] !== ""
          ? parseFloat(serviceSubChrArr["servicechrg_amount"])
          : 0;

      if (servicechargePer > 0 && parseFloat(cartSubTotal) > 0) {
        servicechrgAmunt = (servicechargePer / 100) * parseFloat(cartSubTotal);
      }

      serviceChrgAmt = servicechrgAmunt;
      servicechargeDisplaylabel = serviceSubChrArr["servicechrg_displaylabel"];
      surChargeAmt =
        serviceSubChrArr["surcharge_amount"] !== ""
          ? parseFloat(serviceSubChrArr["surcharge_amount"])
          : 0;
    }
    cartGrandTotal =
      parseFloat(cartGrandTotal) + parseFloat(serviceChrgAmt) + surChargeAmt;

    var promotionApplied = this.state.promotion_applied;
    var promotionType = this.state.promotion_type;
    var promotionAmount = this.state.promotion_amount;
    var promotionSource = this.state.promotion_source;
    var promoIsDelivery = this.state.promoIs_delivery;

    promotionAmount = promotionAmount != "" ? promotionAmount : 0;

    if (promotionApplied === "Yes") {
      cartGrandTotal =
        parseFloat(cartGrandTotal) > parseFloat(promotionAmount)
          ? parseFloat(cartGrandTotal) - parseFloat(promotionAmount)
          : 0;
    }

    var comSettings = this.state.settings;
    var gstPercentage = 0;
    var gstAmount = 0;
    var deliveryCharge = 0;
    if (Object.keys(comSettings).length > 0) {
      deliveryCharge =
        comSettings.client_catering_delivery_charge !== ""
          ? parseFloat(comSettings.client_catering_delivery_charge)
          : 0;
      gstPercentage =
        comSettings.client_tax_surcharge !== ""
          ? parseFloat(comSettings.client_tax_surcharge)
          : 0;

      var gstAmount = getGstValue(gstPercentage, cartGrandTotal, "format");
      var gstAmountTxt = getGstValue(gstPercentage, cartGrandTotal, "");

      cartGrandTotal = parseFloat(cartGrandTotal) + parseFloat(gstAmountTxt);
    }
    if (validation_only === "Yes" && intitalLoad === "No") {
      var popupIdTxt = "#pay-omiseconf-popup";

      $.magnificPopup.open({
        items: {
          src: popupIdTxt,
        },
        type: "inline",
        closeOnBgClick: false,
      });
      return false;
    }

    var postObject = {
      /* cart details */
      app_id: appId,
      availability_id: cateringId,
      outlet_id: cookie.load("cateringOutletId"),
      zone_id: this.state.zone_id,
      sub_total: cartSubTotal,
      grand_total: cartGrandTotal,
      order_source: "Web",
      order_status: 1,
      payment_mode: "1",
      payment_type: "CASH",
      validation_only: validationOnly,

      order_subcharge_amount: surChargeAmt,
      order_service_charge: servicechargePer,
      order_service_charge_amount: serviceChrgAmt,
      order_servicecharge_displaylabel: servicechargeDisplaylabel,

      order_date: orderDate,
      order_remarks: this.state.catering_specil_note,
      products: JSON.stringify(products),

      cateringhall: JSON.stringify(postHall),

      tax_charge: gstPercentage,
      order_tax_calculate_amount: gstAmount,
      delivery_charge: deliveryCharge,

      order_tat_time: "",
      order_is_advanced: "No",
      order_advanced_date: "",
      pickup_time: orderDate,
      pickup_outlet_id: "",
      call_center_admin_id: "",

      /* customer  Details */
      customer_id: cookie.load("UserId"),
      customer_address_id: customerAddressId,
      customer_fname: cookie.load("UserFname"),
      customer_lname: cookie.load("UserLname"),
      customer_mobile_no: cookie.load("UserMobile"),
      customer_email: cookie.load("UserEmail"),
      customer_address_line1: orderAddress,
      customer_address_line2: orderAddress2,
      customer_postal_code: orderPostalCode,
      customer_unit_no1: unitnumber1,
      customer_unit_no2: unitnumber2,

      customer_city: "",
      customer_state: "",
      customer_country: "",

      breaktime_enable: finalCartDetails.cart_breaktime_enable,
      breaktime_count: finalCartDetails.cart_breaktime_count,
      breaktime_list: finalCartDetails.cart_breaktime_list,

      /* discount */
      redeem_applied: "No",
      voucher_applied: "No",
      coupon_applied: "No",
      discount_applied: "No",
      discount_amount: "No",
    };

    if (promotionApplied === "Yes" && parseFloat(promotionAmount) > 0) {
      postObject["discount_applied"] = "Yes";
      postObject["discount_amount"] = promotionAmount;

      if (promotionType === "promoCode") {
        var categoryIdsDet = this.getProductIdsDet(finalcartItems);

        postObject["coupon_applied"] = "Yes";
        postObject["promo_code"] = promotionSource;
        postObject["cart_quantity"] = finalCartDetails.cart_total_items;
        postObject["category_id"] = categoryIdsDet;
        postObject["coupon_amount"] = promotionAmount;
        postObject["promotion_delivery_charge_applied"] = promoIsDelivery;
      }

      if (promotionType === "points") {
        postObject["redeem_applied"] = "Yes";
        postObject["redeem_point"] = promotionSource;
        postObject["redeem_amount"] = promotionAmount;
      }
    }

    postObject["reward_point_status"] = "Yes";

    axios
      .post(apiUrlV2 + "Cateringorders/submit_order", qs.stringify(postObject))
      .then((res) => {
        if (res.data.status === "ok") {
          if (validationOnly !== "Yes") {
            showCustomAlert("success", "Nice! Your order placed successfully.");
            var localOrderNo = res.data.common.local_order_no;
            this.setState({ placingorderimage: tickImage });
            if (this.state.paymentmodevalue === "Omise") {
              this.captureOmiseAmount(
                captureID,
                res.data.common.order_primary_id,
                localOrderNo
              );
            } else {
              this.showSuccessPage(localOrderNo);
            }
          } else {
            hideLoader("checkout-continue-cls", "class");
          }
        } else {
          showCustomAlert(
            "error",
            "Sorry! Your order can not placed, Something is wrong."
          );
          if (res.data.form_error !== undefined) {
            showAlert("Error", res.data.form_error);
          } else {
            showAlert("Error", res.data.message);
          }
          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });
          hideLoader("checkout-continue-cls", "class");
        }
      });
  }

  /* Omise Payment Gateway Start */
  handleChangeTxt = (item, event) => {
    const re = /^[0-9 \b]+$/;
    if (item == "cardNumber") {
      if (event.target.value === "" || re.test(event.target.value)) {
        var cardnumber = this.space(event.target.value);
        var cardnumberLenght = cardnumber.length;
        if (cardnumberLenght <= 19) {
          this.setState({ [item]: cardnumber });
          this.cardValidation(cardnumber);
        }
      }
    } else if (
      item == "expiration_month" ||
      item == "expiration_year" ||
      item == "security_code"
    ) {
      if (event.target.value === "" || re.test(event.target.value)) {
        this.setState({ [item]: event.target.value });
      }
    } else {
      this.setState({ [item]: event.target.value });
    }

    this.setState({
      omisenameerror: "",
      omisecardrror: "",
      omisemontherror: "",
      omiseyearerror: "",
      omisecodeerror: "",
    });
  };

  space(el) {
    var numbes = el.replace(/ /g, "");
    return numbes.replace(/(\d{4})/g, "$1 ").replace(/(^\s+|\s+$)/, "");
  }

  cardValidation(carnumber) {
    var imagename = "";
    if (carnumber != "") {
      var single = carnumber.substring(0, 1);
      var double = carnumber.substring(0, 2);

      if (single == 4) {
        imagename = "visa";
      } else if (double == 34 || double == 37) {
        imagename = "american";
      } else if (double >= 51 && double <= 55) {
        imagename = "master";
      } else if (double == 60 || double == 64 || double == 65) {
        imagename = "discover";
      }
    }

    this.setState({ cardImage: imagename });
  }

  getOmiseToken() {
    var errorFlg = 0,
      omisenameerror = "",
      omisecardrror = "",
      omisemontherror = "",
      omiseyearerror = "",
      omisecodeerror = "";
    if (this.state.holdername == "") {
      errorFlg++;
      omisenameerror = "omise-form-error";
    }
    if (this.state.cardNumber == "") {
      errorFlg++;
      omisecardrror = "omise-form-error";
    }
    if (this.state.expiration_month == "") {
      errorFlg++;
      omisemontherror = "omise-form-error";
    }
    if (this.state.expiration_year == "") {
      errorFlg++;
      omiseyearerror = "omise-form-error";
    }
    if (this.state.security_code == "") {
      errorFlg++;
      omisecodeerror = "omise-form-error";
    }

    this.setState({
      omisenameerror: omisenameerror,
      omisecardrror: omisecardrror,
      omisemontherror: omisemontherror,
      omiseyearerror: omiseyearerror,
      omisecodeerror: omisecodeerror,
    });

    if (errorFlg === 0) {
      showLoader("omise-pay-btn", "class");
      /* this.state.globalSettings.omise_public_key */
      var omise_public_key = this.state.settings.omise_public_key;
      Omise.setPublicKey(omise_public_key);
      var current = this;
      var card = {
        name: this.state.holdername,
        number: this.state.cardNumber,
        expiration_month: this.state.expiration_month,
        expiration_year: this.state.expiration_year,
        security_code: this.state.security_code,
        livemode: false,
      };
      Omise.createToken("card", card, function (statusCode, response) {
        hideLoader("omise-pay-btn", "class");
        if (statusCode === 200) {
          if (
            response.object == "error" ||
            !response.card.security_code_check
          ) {
            var msgArr = Array();
            msgArr["message"] =
              response.object == "error"
                ? response.message
                : "Invalid card details.";
            current.showOmiselert(msgArr);
          } else {
            $.magnificPopup.close();
            /*current.onProcessOmiseToken(response);*/
            current.setState(
              {
                omise_tokken_response: response,
                omise_tokken_card_id: response.card.id,
                omise_tokken_id: response.id,
              },
              () => {
                current.onProcessOmiseToken(response);
              }
            );
          }
        } else {
          var msgArr = Array();
          msgArr["message"] =
            response.message !== ""
              ? response.message
              : "Sorry!. Error from omise.";
          current.showOmiselert(msgArr);
        }
      });
    } else {
      return false;
    }
  }

  showOmiselert(msgArr) {
    var dataTimeoutId = $(".omise-error-info-div").attr("data-timeoutid");
    clearTimeout(dataTimeoutId);
    $(".omise-error-info-div").hide();
    $(".omise-error-msg").html(msgArr["message"]);
    $("#omise-error-info-div").fadeIn();
    var timeoutid = setTimeout(function () {
      $(".omise-error-info-div").hide();
    }, 6000);
    $(".omise-error-info-div").attr("data-timeoutid", timeoutid);
  }

  /* post stripe account */
  onProcessOmiseToken = (token) => {
    $.magnificPopup.open({
      items: {
        src: ".processing",
      },
      type: "inline",
      showCloseBtn: false,
      midClick: true,
      closeOnBgClick: false,
    });

    var promoTionArr = [];
    promoTionArr["promotionApplied"] = "No";
    promoTionArr["promotionAmount"] = "";
    promoTionArr["promoIsDelivery"] = "";
    let dynmaic_surcharge =
      this.state.surcharge !== "" &&
      typeof this.state.surcharge !== undefined &&
      Number(this.state.surcharge) &&
      this.state.surcharge > 0
        ? parseFloat(this.state.surcharge)
        : 0;
    var zoneDetails =
      this.state.zoneDetails !== "" ? [this.state.zoneDetails] : [];
    var settingsArray = this.state.settings;
    var deliveryChare = 0;
    if (
      settingsArray.zone_order_value_delivery_charge !== "" &&
      typeof settingsArray.zone_order_value_delivery_charge !== undefined &&
      typeof settingsArray.zone_order_value_delivery_charge !== "undefined"
    ) {
      if (settingsArray.zone_order_value_delivery_charge === "1") {
        deliveryChare = this.state.cart_zone_delivery_charge;
      }
    }

    var finalCartDetails = this.state.finalCartDetails;
    var payAmount =
      parseFloat(finalCartDetails.cart_grand_total) + parseFloat(deliveryChare);
    var cartSubTotal = finalCartDetails.cart_sub_total;
    var promotionApplied = this.state.promotion_applied;
    var promotionAmount = this.state.promotion_amount;
    promotionAmount = promotionAmount != "" ? promotionAmount : 0;

    var serviceSubChrArr = this.state.servicesurchargeval;

    if (Object.keys(serviceSubChrArr).length > 0) {
      var servicechargePer =
        serviceSubChrArr["servicechrg_per"] !== ""
          ? parseFloat(serviceSubChrArr["servicechrg_per"])
          : 0;
      var servicechrgAmunt =
        serviceSubChrArr["servicechrg_amount"] !== ""
          ? parseFloat(serviceSubChrArr["servicechrg_amount"])
          : 0;

      if (servicechargePer > 0 && cartSubTotal > 0) {
        servicechrgAmunt = (servicechargePer / 100) * cartSubTotal;
      }
      var serviceChrgAmt = servicechrgAmunt;
      var surChargeAmt =
        serviceSubChrArr["surcharge_amount"] !== ""
          ? parseFloat(serviceSubChrArr["surcharge_amount"])
          : 0;

      payAmount =
        parseFloat(payAmount) +
        parseFloat(serviceChrgAmt) +
        parseFloat(surChargeAmt);
    }

    if (promotionApplied === "Yes") {
      payAmount =
        parseFloat(cartGrandTotal) > parseFloat(promotionAmount)
          ? parseFloat(cartGrandTotal) - parseFloat(promotionAmount)
          : 0;
    }

    var payAmount = parseFloat(payAmount).toFixed(2);
    var requestType =
      stripeReference +
      "-" +
      cookie.load("cateringOutletId") +
      "-" +
      cookie.load("UserId");

    axios
      .get(apiUrl + "paymentv1/getPaymentReferenceId?app_id=" + appId)
      .then((topRes) => {
        if (topRes.data.status === "ok") {
          var paymentRef = topRes.data.payment_ref_id;
          this.setState({ payment_ref_id: paymentRef });

          /*  load process html */
          var postObject = {};
          postObject = {
            app_id: appId,
            token: token.id,
            customer_id: cookie.load("UserId"),
            outlet_id: cookie.load("orderOutletId"),
            paid_amount: payAmount,
            request_type: requestType,
            outlet_name:
              typeof cookie.load("orderOutletName") === "undefined"
                ? ""
                : cookie.load("orderOutletName") + " - " + paymentRef,
          };
          /* if (this.state.orderValidFail === 0) { */
          axios
            .post(apiUrl + "paymentv1/authOmise", qs.stringify(postObject))
            .then((res) => {
              if (res.data.status === "ok") {
                var captureID = res.data.result_set.payment_reference_1;
                var omiseLogId = res.data.result_set.log_id;
                this.setState(
                  { validateimage: tickImage, omise_log_id: omiseLogId },
                  function () {
                    this.orderSubmition("", captureID);
                  }.bind(this)
                );
              } else if (res.data.status === "error") {
                if (res.data.message == "token was already used") {
                  this.omise_search_history();
                } else {
                  $.magnificPopup.close();
                  var errerTxt =
                    res.data.message !== ""
                      ? res.data.message
                      : lang.checkout.order_paymentFail_error1;
                  this.paymentFail(lang.common.alerterrortitle, errerTxt);
                  $.magnificPopup.open({
                    items: {
                      src: ".warining_popup",
                    },
                    type: "inline",
                  });
                  return false;
                }
              } else {
                var currents = this;
                setTimeout(
                  function () {
                    currents.omise_search_history();
                  }.bind(this),
                  2000
                );
              }
            })
            .catch((error) => {
              var currentcatch = this;
              setTimeout(
                function () {
                  currentcatch.omise_search_history();
                }.bind(this),
                2000
              );
            });
          /*  } else {
            $.magnificPopup.close();
            this.paymentFail(
              lang.common.alerterrortitle,
              lang.checkout.order_paymentFail_error3
            );
            $.magnificPopup.open({
              items: {
                src: ".warining_popup",
              },
              type: "inline",
            });

            return false;
          } */
        } else {
          $.magnificPopup.close();
          this.paymentFail(
            lang.common.alerterrortitle,
            lang.checkout.order_paymentFail_error3
          );
          $.magnificPopup.open({
            items: {
              src: ".warining_popup",
            },
            type: "inline",
          });

          return false;
        }
      })
      .catch((error) => {
        console.log(error);
        $.magnificPopup.close();
        this.paymentFail(
          lang.common.alerterrortitle,
          lang.checkout.order_paymentFail_error2
        );
        $.magnificPopup.open({
          items: {
            src: ".warining_popup",
          },
          type: "inline",
        });
        return false;
      });
  };
  paymentFail(header, message) {
    $(".warining_popup").remove();
    $("body").append(
      '<div class="white-popup popup_sec warining_popup custom-warining-invtl"><div class="custom_alert"><div class="custom_alertin"><div class="alert_height"><div class="alert_header">' +
        header +
        '</div><div class="alert_body"><p>' +
        message +
        '</p><div class="alt_btns"><a href="javascript:void(0);" onClick="' +
        this.pageReload() +
        '" class="button popup-modal-dismiss">' +
        lang.common.alertbutton +
        "</a></div></div></div></div></div></div>"
    );
  }
  pageReload = () => {
    setTimeout(function () {
      window.location.reload();
    }, 15000);
  };
  omise_search_history = () => {
    var orderOutlet_Id = cookie.load("orderOutletId");
    var postObject = {};
    postObject = {
      app_id: appId,
      card_id: this.state.omise_tokken_card_id,
      token_id: this.state.omise_tokken_id,
      outlet_id: orderOutlet_Id,
    };

    axios
      .post(apiUrl + "paymentv1/omise_search_details", qs.stringify(postObject))
      .then((res) => {
        if (res.data.status === "ok") {
          var captureID = res.data.captureID;
          var omiseLogId = res.data.log_id;
          this.setState(
            { validateimage: tickImage, omise_log_id: omiseLogId },
            function () {
              this.orderSubmition("", captureID);
            }.bind(this)
          );
        } else if (res.data.status === "error") {
          /* Reset poup message -  start */
          this.onProcessOmiseToken(this.state.omise_tokken_response);
        } else {
          this.onProcessOmiseToken(this.state.omise_tokken_response);
        }
      });
  };

  /* Omise Capture Payment */
  captureOmiseAmount(captureID, orderPrimaryId, localOrderNo) {
    var cabtureObjects = {};
    cabtureObjects = {
      payment_reference: stripeReference,
      customer_id: cookie.load("UserId"),
      app_id: appId,
      token: captureID,
      order_id: orderPrimaryId,
      log_id: this.state.omise_log_id,
      outlet_id: cookie.load("orderOutletId"),
    };

    axios
      .post(
        apiUrl + "paymentv1/captureAmountOmise",
        qs.stringify(cabtureObjects)
      )
      .then((captureRes) => {
        if (captureRes.data.status === "ok") {
          this.setState({ completingpaymentimage: tickImage });

          this.setState(
            { stop_authentication: "yes" },
            function () {
              this.showSuccessPage(localOrderNo);
            }.bind(this)
          );
        } else if (captureRes.data.status === "pending") {
          this.retrieve_charge_details(captureID, localOrderNo, orderPrimaryId);
        } else if (captureRes.data.status === "error") {
          var magnfPopup = $.magnificPopup.instance;
          var omsMsgTxt =
            captureRes.data.message != ""
              ? captureRes.data.message
              : lang.checkout.order_captureAmt_error;
          showAlert(lang.common.alerterrortitle, omsMsgTxt, magnfPopup);
          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });
        } else {
          /*Get Status of Charge ID*/
          this.retrieve_charge_details(captureID, localOrderNo, orderPrimaryId);
        }
      })
      .catch((error) => {
        this.captureOmiseAmount(captureID, orderPrimaryId, localOrderNo);
      });
  }

  retrieve_charge_details = (captureID, localOrderNo, orderPrimaryId) => {
    var orderOutlet_Id = cookie.load("orderOutletId");
    var postObject = {};
    postObject = {
      charge_id: captureID,
      app_id: appId,
      order_primary_id: orderPrimaryId,
      outlet_id: orderOutlet_Id,
    };

    axios
      .post(
        apiUrl + "paymentv1/retrieve_charge_details",
        qs.stringify(postObject)
      )
      .then((res) => {
        if (
          res.data.status === "ok" &&
          res.data.payment_status === "successful"
        ) {
          this.setState(
            { stop_authentication: "yes" },
            function () {
              this.showSuccessPage(localOrderNo);
            }.bind(this)
          );
        } else if (
          res.data.status === "ok" &&
          res.data.payment_status === "pending"
        ) {
          this.retrieve_charge_details(captureID, localOrderNo, orderPrimaryId);
        } else {
          var magnfPopup = $.magnificPopup.instance;
          var msgTxt =
            res.data.message != ""
              ? res.data.message
              : lang.checkout.order_captureAmt_error;
          showAlert(lang.common.alerterrortitle, msgTxt, magnfPopup);
          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });
        }
      });
  };

  /* sucess page */
  showSuccessPage(localOrderNo) {
    this.destroyCart();
    this.deleteOrderCookie();
    const { history } = this.props;
    setTimeout(function () {
      $.magnificPopup.close();
      history.push("/catering/thankyou/" + localOrderNo);
    }, 1000);
  }

  /* this function used to delte all  cart items */
  destroyCart() {
    var postObject = {};
    postObject = { app_id: appId };
    postObject["customer_id"] = cookie.load("UserId");
    axios.post(apiUrlV2 + "cateringcart/destroy", qs.stringify(postObject));
  }

  /* this function used to  delete all cookie values */
  deleteOrderCookie() {
    removePromoCkValue();

    cookie.remove("catering_categoryPryId");
    cookie.remove("catering_categoryId");
    cookie.remove("catering_categoryName");

    cookie.remove("venueTypeInitail");
    cookie.remove("catering_eventType");
    cookie.remove("cateringOutletId");
    cookie.remove("cateringOutletName");
    cookie.remove("cateringSelectedOutlet");
    cookie.remove("cateringOutletPostalcode");
    cookie.remove("cateringOutletaddressLine");
    cookie.remove("cateringOutletAddress");

    cookie.remove("cateringHallId");
    cookie.remove("cateringHallName");
    cookie.remove("cateringHallPostalcode");
    cookie.remove("cateringHalladdressLine");
    cookie.remove("cateringHalladdress");
    cookie.remove("cateringHallPax");
    cookie.remove("cateringHallCharge");

    cookie.remove("cateringDate");
    cookie.remove("cateringTime");
    cookie.remove("breaktimeEnable");
    cookie.remove("breaktimeCount");
    cookie.remove("breaktimeList");

    cookie.remove("cartDetail");
    cookie.remove("cartTotalItems");
    cookie.remove("fromCkeckOutVld");
    cookie.remove("applyCatrngPromo");
  }

  handleShowAlertFun(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  downloadCartItem() {
    showLoader("download-quote", "class");
    var customerId = cookie.load("UserId");
    var customerEmail = cookie.load("UserEmail");
    var promotionAmount = this.state.promotion_amount;
    var comSettings = this.state.settings;
    var gstPar = 0;
    if (Object.keys(comSettings).length > 0) {
      gstPar =
        comSettings.client_tax_surcharge !== ""
          ? parseFloat(comSettings.client_tax_surcharge)
          : 0;
    }
    var outletIdVl = cookie.load("cateringOutletId");

    var urlParam =
      "&customer_id=" +
      customerId +
      "&request_email=" +
      customerEmail +
      "&client_gst_charge=" +
      gstPar +
      "&promotion=" +
      promotionAmount +
      "&outlet_id=" +
      outletIdVl;

    axios
      .get(
        apiUrlV2 + "cateringcart/downloadQtnItems?app_id=" + appId + urlParam
      )
      .then((res) => {
        if (res.data.status === "ok") {
          showCustomAlert(
            "success",
            "Your Quotation request has been sent to your registered email please check!."
          );
          this.handleShowAlertFun(
            "success",
            "Your Quotation request has been sent to your registered email please check!."
          );
        } else {
          showCustomAlert("error", "Sorry! Your Quotation request was failed.");
          this.handleShowAlertFun(
            "error",
            "Sorry! Your Quotation request was failed."
          );
        }
        hideLoader("download-quote", "class");
      });
  }

  CheckTarmsContnChk() {
    var tarmsContn = this.state.catering_tarms_contn;
    var chkBox = false;
    if (tarmsContn === "Yes") {
      chkBox = true;
    }
    return chkBox;
  }

  changeTarmsContnChk() {
    var tarmsContn = this.state.catering_tarms_contn;
    if (tarmsContn === "Yes") {
      this.setState({ catering_tarms_contn: "No" });
    } else {
      this.setState({ catering_tarms_contn: "Yes" });
    }
    $(".tarms_chkbox_div").removeClass("err_tarms_chk");
  }

  /* ViewProducts */

  choosePayment(paymentMode) {
    cookie.save("paymentMode", paymentMode, { path: "/" });
    if (paymentMode === "Cash") {
      this.setState({ paymentmodevalue: "Cash" });
    } else if (paymentMode === "Offline") {
      this.setState({ paymentmodevalue: "Offline" });
      this.offlinePaymentPopup();
    } else if (paymentMode === "Stripe") {
      this.setState({ paymentmodevalue: "Stripe" });
    } else if (paymentMode === "Omise") {
      this.setState({ paymentmodevalue: "Omise" });
    } else {
      this.setState({ paymentmodevalue: "promotion" });
    }
  }
  orderPayValidaion(payGetWayType, event) {
    event.preventDefault();
    this.orderSubmition("Yes");
  }

  render() {
    var eventTypeTxt =
      typeof cookie.load("catering_eventType") !== "undefined"
        ? cookie.load("catering_eventType")
        : "";
    var showLocationName = cookie.load("cateringHallName");
    var showLocationAddr = cookie.load("cateringHalladdress");

    var cateringDateTxt = cookie.load("cateringDate");
    var cateringTimeTxt = cookie.load("cateringTime");

    var showRdmPoints =
      parseFloat(this.state.reward_point_count) -
      parseFloat(this.state.used_reward_point);
    showRdmPoints = showRdmPoints.toFixed(2);

    var finalCartDetails = this.state.finalCartDetails;
    var payAmount = finalCartDetails.cart_grand_total;
    var cartSubTotal = finalCartDetails.cart_sub_total;
    var promotionApplied = this.state.promotion_applied;
    var promotionAmount = this.state.promotion_amount;
    promotionAmount = promotionAmount != "" ? promotionAmount : 0;
    var serviceSubChrArr = this.state.servicesurchargeval;

    if (Object.keys(serviceSubChrArr).length > 0) {
      var servicechargePer =
        serviceSubChrArr["servicechrg_per"] !== ""
          ? parseFloat(serviceSubChrArr["servicechrg_per"])
          : 0;
      var servicechrgAmunt =
        serviceSubChrArr["servicechrg_amount"] !== ""
          ? parseFloat(serviceSubChrArr["servicechrg_amount"])
          : 0;

      if (servicechargePer > 0 && cartSubTotal > 0) {
        servicechrgAmunt = (servicechargePer / 100) * cartSubTotal;
      }
      var serviceChrgAmt = servicechrgAmunt;
      var surChargeAmt =
        serviceSubChrArr["surcharge_amount"] !== ""
          ? parseFloat(serviceSubChrArr["surcharge_amount"])
          : 0;

      payAmount =
        parseFloat(payAmount) +
        parseFloat(serviceChrgAmt) +
        parseFloat(surChargeAmt);
    }

    if (promotionApplied === "Yes") {
      payAmount =
        parseFloat(payAmount) > parseFloat(promotionAmount)
          ? parseFloat(payAmount) - parseFloat(promotionAmount)
          : 0;
    }

    return (
      <div>
        {/*<h4 className={"tab_mobtrigger tab_mobtrigger_cls "+this.props.showsActiveHdr(4)} onClick={this.setPropActiveTab.bind(this,4)}>CHECKOUT<i></i></h4>*/}
        <div
          className="catering_checout catering_checout_maindiv tab_mobrow"
          style={{ display: this.props.showsActiveDiv(4) }}
        >
          <form
            id="checkoutpage_form"
            className=" form_sec checkout-total"
            method="post"
            acceptCharset="utf-8"
          >
            <div className="cdd-details">
              {/* cdd-details left start */}
              <div className="cdd-details-lhs fl">
                <div className="text-center checkout-heading">
                  <span className="text-uppercase">Your Location & Time</span>
                </div>

                <div className="checkout-control-inner-main">
                  {eventTypeTxt === "venue" && (
                    <div className="checkout-control-group-top">
                      <label className="chk_hea">Event Location</label>
                      <div className="form-group">
                        <div className="focus-out controls-single">
                          <input
                            type="text"
                            className="cust_address_cls form-control input-focus"
                            placeholder="Address"
                            value={this.state.catering_address_line}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="form-group controls-three">
                        <div className="focus-out">
                          <label>Postal Code</label>
                          <input
                            type="text"
                            className="form-control input-focus postal_code_cls"
                            value={this.state.catering_postal_code}
                            onChange={this.changePostalCode.bind(this, "cust")}
                            onKeyPress={(e) => this.validateIntval(e)}
                          />
                        </div>

                        <div className="focus-out">
                          <label>Unit No 01</label>
                          <input
                            type="text"
                            name="floor_no"
                            className="form-control input-focus cust_floor_no"
                            value={this.state.catering_floor_no}
                            onKeyPress={(e) => this.validatezip(e)}
                            onChange={this.handleAddrChange.bind(this)}
                          />
                        </div>

                        <div className="focus-out">
                          <label>Unit No 02</label>
                          <input
                            type="text"
                            name="unit_no"
                            value={this.state.catering_unit_no}
                            className="form-control input-focus"
                            onKeyPress={(e) => this.validatezip(e)}
                            onChange={this.handleAddrChange.bind(this)}
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  {eventTypeTxt === "venue" && (
                    <div className="checkout-control-group-top">
                      <label className="chk_hea">
                        Billing Address{" "}
                        <span>
                          Same As Delivery Address{" "}
                          <div className="custom_checkbox">
                            <input
                              type="checkbox"
                              name="bill_chk"
                              className="addon_list_single"
                              onChange={this.changeBillingAddrChk.bind(this)}
                              checked={this.CheckBillingAddrChk("checkbox")}
                            />
                            <span></span>{" "}
                          </div>
                        </span>
                      </label>
                      <div
                        className="check-billing"
                        style={{ display: this.CheckBillingAddrChk("display") }}
                      >
                        <div className="form-group">
                          <div className="focus-out controls-single">
                            <input
                              type="text"
                              className="form-control input-focus billing_address_cls"
                              placeholder=""
                              readOnly
                              value={this.state.billing_address_line}
                            />
                          </div>
                        </div>
                        <div className="form-group controls-three">
                          <div className="focus-out">
                            <label>Postal Code</label>
                            <input
                              type="text"
                              name="billing_postcode"
                              value={this.state.billing_postal_code}
                              onChange={this.changePostalCode.bind(
                                this,
                                "billing"
                              )}
                              onKeyPress={(e) => this.validateIntval(e)}
                              className="form-control input-focus billing_postal_code_cls"
                            />
                          </div>

                          <div className="focus-out">
                            <label className="unit-num">Unit No 01</label>
                            <input
                              type="text"
                              name="bill_floor_no"
                              value={this.state.billing_floor_no}
                              onKeyPress={(e) => this.validatezip(e)}
                              onChange={this.handleAddrChange.bind(this)}
                              className="form-control input-focus billing_floor_no"
                            />
                          </div>

                          <div className="focus-out">
                            <label className="unit-num">Unit No 02</label>
                            <input
                              type="text"
                              name="bill_unit_no"
                              value={this.state.billing_unit_no}
                              onKeyPress={(e) => this.validatezip(e)}
                              onChange={this.handleAddrChange.bind(this)}
                              className="form-control input-focus"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {eventTypeTxt !== "venue" && (
                    <div className="checkout-control-group-top">
                      <label className="chk_hea">Event Location</label>
                      <div>
                        <span className="making-place display_block">
                          {showLocationName}
                        </span>
                        <span>{showLocationAddr}</span>
                      </div>
                      <a
                        href="javascript:void(0);"
                        onClick={this.swtCartFun.bind(this)}
                        className=""
                      >
                        Change Location
                      </a>
                    </div>
                  )}

                  <div className="checkout-control-group-middle">
                    <label className="chk_hea">Event Date & Time</label>

                    <div className="row-replace">
                      <div className="col-md-cls col-xs-cls">
                        <div className="form-group">
                          <div className="input_area">
                            <input
                              type="text"
                              name="catering_date"
                              className="form-control datetimepicker filled catering_date_cls"
                              value={dateConvFun(cateringDateTxt, 2)}
                              id="datetimepicker3"
                              placeholder="30 - 10- 2018"
                              readOnly
                            />
                          </div>

                          <div className="input_area">
                            <input
                              type="text"
                              name="catering_time"
                              className="form-control datetimepicker filled catering_time_cls"
                              value={timeToConv12(cateringTimeTxt)}
                              id="datetimepicker4"
                              placeholder="12:00 PM"
                              readOnly
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <a href="javascript:void(0);" onClick={this.setPropActiveTab.bind(this,2)} className="change-edt">
										Change Event Date & Time
										</a> */}
                    <a
                      href="javascript:void(0);"
                      onClick={this.setDateTimeFun.bind(this)}
                      className="change-edt"
                    >
                      Change Event Date & Time
                    </a>
                  </div>

                  <div className="checkout-control-group-bottom">
                    <label className="chk_hea">Special Instruction</label>
                    <textarea
                      className=""
                      name="specil_note"
                      value={this.state.catering_specil_note}
                      onChange={this.handleAddrChange.bind(this)}
                      placeholder="Please enter your special message here..."
                      id="orderRemarks"
                    ></textarea>

                    {this.state.termsAndConditions !== "" && (
                      <div>
                        <div className="checkout-terms-and-condition">
                          {this.state.termsAndConditions}
                        </div>
                        <div className="custom_checkbox tarms_chkbox_div">
                          <input
                            type="checkbox"
                            name="tarms_chk"
                            onClick={this.changeTarmsContnChk.bind(this)}
                            defaultChecked={this.CheckTarmsContnChk()}
                          />
                          <span>
                            I have read and accept terms and conditions
                          </span>
                        </div>
                      </div>
                    )}
                  </div>

                  <div
                    className="white-popup mfp-hide popup_sec"
                    id="swt_popup_id"
                    style={{ maxWidth: 500 }}
                  >
                    <div className="custom_alert">
                      <div className="custom_alertin">
                        <div className="alert_height">
                          <div
                            className="alert_header"
                            style={{ textAlign: "center" }}
                          >
                            Wait a second !
                          </div>
                          <div className="alert_body">
                            <img src={CantDlyImg} />
                            <p>
                              By switching you are about to clear your cart.
                            </p>
                            <p>Do you wish to proceed ?</p>
                            <div className="alt_btns">
                              <a
                                href="javascript:void(0);"
                                className="btn btn-sm btn_yellow popup-modal-dismiss"
                                style={{ background: "#e0dddd" }}
                              >
                                Cancel
                              </a>
                              <a
                                href="javascript:void(0);"
                                className="btn btn-sm btn_yellow"
                                onClick={this.yesProcdFun.bind(this)}
                              >
                                Yes Proceed
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* cdd-details left end */}

              {/* cart details Start */}
              <div className="cdd-details-rhs fl">
                <Cartdetails
                  chekoutState={this.state}
                  satePackChangefun={this.satePackChange}
                  prpActiveTab={this.props.setActiveTab}
                  chkoutSateVal={this.chkoutSateValChange}
                />
              </div>
              {/* cart details End */}
            </div>
          </form>

          {/* redeem points & promo Start */}
          <div className="redeem">
            <div className="redeem-row">
              {/*<div className="redeem-col">
									<div className="redeem-item">
									<div className="redeem-item-hea">
										<div className="redeem-item-hea-inner">
											<h4>Get Redeem</h4>
											<span>Redeem your points here</span>
										</div>
										{(parseFloat(this.state.reward_point_count)>0)?<Link to={"/rewards"} className="points">You have <b>{showRdmPoints} points</b> available</Link>:''}
									</div>
									<div className="redeem_apply focus-out applypoints_cls">
										<div className="focus-out">
											{(parseFloat(this.state.reward_point_count)>0)?<label>You Can Redeem {showRdmPoints} Points</label>:<label>No Points Available</label>}
											<input type="input" name="reward_point" value={this.state.reward_point_val} className="form-control input-focus" onKeyPress={(e) => this.validateFloatval(e)} onChange={this.handleAddrChange.bind(this)} />
										</div>
										{(this.state.promotion_applied === 'Yes' && this.state.promotion_type === 'points')?<button className="btn btn_black btn_minwid" onClick={this.removePointsAndPromo.bind(this)} >Remove</button>:<button className="btn btn_black btn_minwid" onClick={this.applyPointsAndPromo.bind(this,'points')} >Apply Now</button>}
									</div>
									</div>
							</div>*/}
              <div className="redeem-col redeem-col-full redeem-col-right">
                <div className="redeem-item">
                  <div className="redeem-item-hea">
                    <div className="redeem-item-hea-inner">
                      <h4>Promo Code</h4>
                      <span>Apply your promo code here</span>
                    </div>
                    {parseFloat(this.state.promotion_count) > 0 ? (
                      <Link to={"/mypromotions"} className="points">
                        You have <b>{this.state.promotion_count} Promotions</b>{" "}
                        available
                      </Link>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="redeem_apply focus-out applypromo_cls">
                    <div
                      className={
                        this.state.promo_code_val != ""
                          ? "focus-out focused"
                          : "focus-out"
                      }
                    >
                      <label>Add your promo code here</label>
                      <input
                        type="input"
                        name="promo_code"
                        value={this.state.promo_code_val}
                        className="form-control input-focus"
                        onChange={this.handleAddrChange.bind(this)}
                      />
                    </div>
                    {this.state.promotion_applied === "Yes" &&
                    this.state.promotion_type === "promoCode" ? (
                      <button
                        className="btn btn_black btn_minwid"
                        onClick={this.removePointsAndPromo.bind(this)}
                      >
                        Remove
                      </button>
                    ) : (
                      <button
                        className="btn btn_black btn_minwid"
                        onClick={this.applyPointsAndPromo.bind(
                          this,
                          "promoCode"
                        )}
                      >
                        Apply Now
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* redeem points & promo End */}

          {/* chk-bffoot div - start */}
          <div className="chk-bffoot">
            {/* chk-payment div - start */}
            <div className="chk-payment">
              <div className="chk-payment-row">
                <div className="chk-payment-title">
                  <h3>Select Your Payment Method</h3>
                </div>
                <div className="chk-payment-col">
                  <form action="#">
                    {this.state.paymentmodevalue !== "promotion" &&
                      parseFloat(payAmount) > 0 && (
                        <ul className="chk-payment-col-radio">
                          {this.state.omise_payment_enable === 1 && (
                            <li className="custom_checkbox">
                              <input
                                type="radio"
                                className="paymentRadio"
                                defaultChecked={
                                  this.state.paymentmodevalue == "Omise"
                                    ? "checked"
                                    : ""
                                }
                                id="omscard"
                                onClick={this.choosePayment.bind(this, "Omise")}
                                name="radio-group"
                              />
                              <label htmlFor="omscard"></label>
                              <div className="radio_con">
                                <img src={paymentImage} />
                              </div>
                            </li>
                          )}
                        </ul>
                      )}
                  </form>
                </div>
                <div className="chk-payment-col"></div>
              </div>
            </div>
            {/* chk-payment div - end */}
          </div>
          {/* chk-bffoot div - end */}

          <div className="chk-payment-btn">
            <div className="chk-payment-btn-row checkout-continue-cls">
              <div className="row-replace">
                <div className="col-md-cls go_second download-quote">
                  <a
                    href="javascript:void(0)"
                    onClick={this.downloadCartItem.bind(this)}
                    className="button"
                    title="Next"
                  >
                    Download Quotation
                  </a>
                </div>
                <div className="col-md-cls con_first">
                  <a
                    href="javascript:void(0)"
                    onClick={this.orderPayValidaion.bind(this, "Omise")}
                    /* onClick={this.orderSubmition.bind(this)} */
                    className="button"
                    title="Next"
                  >
                    Place Order
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          id="processing-popup"
          className="white-popup mfp-hide popup_sec processing"
        >
          <div className="pouup_in">
            <h3 className="title1 text-center">We Are Processing Your Order</h3>
            <div className="process_inner">
              {this.onlinePaymentLoading()}
              {this.orderBCLoading()}
              {this.amountCaptureLoading()}
            </div>
          </div>
        </div>

        <Packageupdate
          chekoutState={this.state}
          prpActiveTab={this.props.setActiveTab}
          chkoutSateVal={this.chkoutSateValChange}
        />

        {/*Payment omise confirm popup Start*/}
        <div
          id="pay-omiseconf-popup"
          className="white-popup mfp-hide popup_sec pay-omiseconf-popup"
        >
          <div
            className="omise-error-info-div"
            id="omise-error-info-div"
            data-timeoutid=""
            style={{ display: "none" }}
          >
            <div className="container">
              <span className="omise-error-icon">
                <i
                  className="fa fa-exclamation-triangle"
                  aria-hidden="true"
                ></i>
              </span>
              <a
                href="javascript:void(0)"
                className="omise_alert_close"
                data-dismiss="alert"
                aria-label="Close"
              >
                {" "}
                <span aria-hidden="true">×</span>{" "}
              </a>
              <p className="omise-error-msg">Something went wrong</p>
            </div>
          </div>

          {this.state.settings.omise_envir === "dev" && (
            <div className="omisepay-mode-option">Test Mode</div>
          )}

          <div className="custom_alert">
            <div className="custom_alertin">
              <div className="alert_height">
                <div className="omise_pay_header">
                  <span className="omise-pay-img">
                    <img className="makisan-logo-img" src={stripeImage} />
                  </span>
                  <span className="omise-pay-title">Ajmir Biryani</span>
                </div>
                <div className="omise_pay_body">
                  <div className="omisepop_in">
                    <div className="form_sec">
                      <div className={"uname-box " + this.state.omisenameerror}>
                        <i className="fa fa-user" data-unicode="f007"></i>
                        <input
                          type="text"
                          id="holdername"
                          className="form-control"
                          placeholder="Card Holder Name"
                          value={this.state.holdername}
                          onChange={this.handleChangeTxt.bind(
                            this,
                            "holdername"
                          )}
                        />
                      </div>

                      <div
                        className={"card-numbr-div " + this.state.omisecardrror}
                      >
                        <i
                          className="fa fa-credit-card"
                          data-unicode="f09d"
                        ></i>
                        <input
                          type="text"
                          className={"form-control " + this.state.cardImage}
                          placeholder="Card Number"
                          value={this.state.cardNumber}
                          id="omise_card_number"
                          onChange={this.handleChangeTxt.bind(
                            this,
                            "cardNumber"
                          )}
                        />
                        <span className="card"></span>
                      </div>

                      <div className="form_expire_row">
                        <div className="expire_row_inner">
                          <div
                            className={
                              "expire_left " + this.state.omisemontherror
                            }
                          >
                            <i
                              className="fa fa-calendar-o"
                              data-unicode="f133"
                            ></i>
                            <input
                              type="text"
                              className="form-control"
                              maxLength="2"
                              id="expiration_month"
                              placeholder="MM"
                              value={this.state.expiration_month}
                              onChange={this.handleChangeTxt.bind(
                                this,
                                "expiration_month"
                              )}
                            />
                          </div>

                          <div
                            className={
                              "expire_mdl " + this.state.omiseyearerror
                            }
                          >
                            <input
                              type="text"
                              placeholder="YY"
                              className="form-control"
                              maxLength="4"
                              id="expiration_year"
                              value={this.state.expiration_year}
                              onChange={this.handleChangeTxt.bind(
                                this,
                                "expiration_year"
                              )}
                            />
                          </div>

                          <div
                            className={
                              "expire_right " + this.state.omisecodeerror
                            }
                          >
                            <i className="fa fa-lock" data-unicode="f023"></i>
                            <input
                              type="password"
                              maxLength="3"
                              className="form-control"
                              id="security_code"
                              placeholder="CVV"
                              value={this.state.security_code}
                              onChange={this.handleChangeTxt.bind(
                                this,
                                "security_code"
                              )}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="alt_btns">
                        {this.state.settings.omise_public_key &&
                          cookie.load("UserMobile") != "" &&
                          this.state.paymentmodevalue == "Omise" &&
                          this.state.omise_payment_enable === 1 && (
                            <button
                              onClick={this.getOmiseToken.bind(this)}
                              className="button omise-pay-btn"
                            >
                              Pay {stripeCurrency} {showPriceValue(payAmount)}
                            </button>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*payment omise confirm popup - End*/}
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  var overAllCateringCart = Array();
  var cateringCartDetails = Array();
  var cateringCartItems = Array();
  var cateringCartTotalItmCount = 0;
  if (Object.keys(state.cateringcartlistdetail).length > 0) {
    var resultSetArr = !("result_set" in state.cateringcartlistdetail[0])
      ? Array()
      : state.cateringcartlistdetail[0].result_set;
    if (
      state.cateringcartlistdetail[0].status === "ok" &&
      Object.keys(resultSetArr).length > 0
    ) {
      overAllCateringCart = resultSetArr;
      cateringCartDetails = resultSetArr.cart_details;
      cateringCartItems = resultSetArr.cart_items;
      cateringCartTotalItmCount = resultSetArr.cart_details.cart_total_items;
    }
  }

  var blacksArr = Array();
  if (Object.keys(state.staticblack).length > 0) {
    if (state.staticblack[0].status === "ok") {
      blacksArr = state.staticblack[0].result_set;
    }
  }

  return {
    overAllCateringCart: overAllCateringCart,
    cateringCartDetails: cateringCartDetails,
    cateringCartItems: cateringCartItems,
    cateringCartTotalItmCount: cateringCartTotalItmCount,
    staticblack: blacksArr,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCateringCartDetail: () => {
      dispatch({ type: GET_CATERINGCART_DETAIL });
    },
    getStaticBlock: () => {
      dispatch({ type: GET_STATIC_BLOCK });
    },
  };
};
export default connect(
  mapStateTopProps,
  mapDispatchToProps
)(withRouter(Checkout));
