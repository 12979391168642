/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import cookie from "react-cookies";
import Slider from "react-slick";
import { bindActionCreators } from "redux";
import { GET_ACTIVITYCOUNT } from "../../actions";

var currenturl = window.location.href;
var myaccount = "myaccount";
var myorders = "myorders";
var rewards = "rewards";
var mypromotions = "mypromotions";
var myvouchers = "myvouchers";

let initialSlide = 0;

if(currenturl.includes(myaccount) === true){
  initialSlide = 0;
}
if(currenturl.includes(myorders) === true){
  initialSlide = 1;
}
if(currenturl.includes(rewards) === true){
  initialSlide = 2;
}
if(currenturl.includes(mypromotions) === true){
  initialSlide = 3;
}

var settingsMyAcc = {
  infinite: false,
  slidesToShow: 4,
  slidesToScroll: 4,
  initialSlide: initialSlide,
  responsive: [
    {
      breakpoint: 1191,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: false,
      },
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: false,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
      },
    },
  ],
};
class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: this.props.pageName,
      deliveryTakeaway_orders: "",
      login_route: cookie.load("login_route") ? cookie.load("login_route") : "",
    };

    const inputKeys = ["deliveryTakeaway_orders"];
    this.props.getActivityCount(JSON.stringify(inputKeys));
  }
  componentWillReceiveProps(nextProps) {
    /*activity count -start */
    if (Object.keys(nextProps.activitycountNew).length > 0) {
      if (nextProps.activitycountNew !== this.props.activitycountNew) {
        if (
          nextProps.activitycountNew[0].status &&
          nextProps.activitycountNew[0].result_set
        ) {
          this.setState({
            deliveryTakeaway_orders:
              nextProps.activitycountNew[0].result_set.deliveryTakeaway_orders,
          });
        }
      }
    } else {
      this.setState({
        order_all: 0,
        overall_orders: 0,
        catering_orders: 0,
        deliveryTakeaway_orders: 0,
        reservation_orders: 0,
      });
    }
  }
  render() {
    return (
      <>
        <div className="mbacc_mslidersec mbacc_mslider ">
          <Slider {...settingsMyAcc}>
            <div
              className={"mbacc_mslide "+(currenturl.includes(myaccount) === true?"active":"")}
            >
              <a href="/myaccount" title="My Account">
                <span>Account Details</span>
              </a>
            </div>
            <div
              className={"mbacc_mslide "+(currenturl.includes(myorders) === true?"active":"")}
            >
              <a href="/myorders" title="My Orders">
                <span>
                  Orders{" "}
                  {parseFloat(this.state.deliveryTakeaway_orders) > 0 && (
                    <strong className="count_num_t">
                      {this.state.deliveryTakeaway_orders}
                    </strong>
                  )}
                </span>
              </a>
            </div>
            <div
              className={"mbacc_mslide "+(currenturl.includes(rewards) === true?"active":"")}
            >
              <Link to="/rewards" title="My Rewards">
                <span>Rewards</span>
              </Link>
            </div> 
            
            
            <div
              className={"mbacc_mslide "+(currenturl.includes(mypromotions) === true?"active":"")}
            >
              <a href="/mypromotions" title="My Promotions">
                <span>Promotions</span>
              </a>
            </div>
            {this.props.pageName == "myaccount" &&
                this.state.login_route !== "guest" && (
            <div className="mbacc_mslide">
              <a href="#change-password-popup" className="open-popup-link">
                Change Password
              </a>
            </div>
            )}
            <div className="mbacc_mslide">
              <a href="/logout">Logout</a>
            </div>

            {/* <li
              className={this.state.activePage === "myvouchers" ? "active" : ""}
            >
              <Link to="/myvouchers" title="Vouchers">
                <span>Vouchers</span>
              </Link>
            </li> */}


          </Slider>
        </div>
        <div className="accmenu_sec">
          <div className="setting_menu_list">
            <ul>
              <li
                className={
                  this.state.activePage === "myaccount" ? "active" : ""
                }
              >
                <Link to="/myaccount" title="My Account">
                  <span>Account Details</span>
                </Link>
              </li>
              <li
                className={this.state.activePage === "myorders" ? "active" : ""}
              >
                <Link to="/myorders" title="My Orders">
                  <span>
                    Orders{" "}
                    {parseFloat(this.state.deliveryTakeaway_orders) > 0 && (
                      <strong className="count_num_t">
                        {this.state.deliveryTakeaway_orders}
                      </strong>
                    )}
                  </span>
                </Link>
              </li>
              
              <li
                className={this.state.activePage === "rewards" ? "active" : ""}
              >
                <Link to="/rewards" title="My Rewards">
                  <span>Rewards</span>
                </Link>
        				</li>
              
              <li
                className={
                  this.state.activePage === "mypromotions" ? "active" : ""
                }
              >
                <Link to="/mypromotions" title="My Promotions">
                  <span>Promotions</span>
                </Link>
              </li>
              {/* <li
              className={this.state.activePage === "myvouchers" ? "active" : ""}
            >
              <Link to="/myvouchers" title="Vouchers">
                <span>Vouchers</span>
              </Link>
            </li> */}
              {this.props.pageName == "myaccount" &&
                this.state.login_route !== "guest" && (
                  <li>
                    <a
                      href="#change-password-popup"
                      className="open-popup-link"
                    >
                      Change Password
                    </a>
                  </li>
                )}
              <li>
                <a href="/logout">Logout</a>
              </li>
            </ul>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    activitycountNew: state.activitycount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getActivityCount: (getObject) => {
      dispatch({ type: GET_ACTIVITYCOUNT, getObject });
    },
  };
};

Sidebar.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Sidebar)
);
