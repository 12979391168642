import { takeEvery, call, put } from 'redux-saga/effects';
import {GET_ZONE_DETAIL, SET_ZONE_DETAIL } from '../actions';
import { appId, apiUrlV2, deliveryId} from "../components/Helpers/Config";
import Axios from 'axios';
import cookie from "react-cookies";

export const watchGetZonedetail = function* () {
  yield takeEvery(GET_ZONE_DETAIL, workerGetZonedetail);
}

function* workerGetZonedetail({ outletId, zoneId, orderdate=null,  ordertime=null}) {
  try {
	var deliveryDate = '';
	var deliveryTime = (cookie.load("deliveryTime") !== undefined)?cookie.load("deliveryTime"):'';
	var deliveryDateTxt = (cookie.load("deliveryDate") !== undefined)?cookie.load("deliveryDate"):'';
	if(deliveryDateTxt != '') {
		var deliveryDateArr = deliveryDateTxt.split("/");
		    deliveryDate = deliveryDateArr[2]+"-"+deliveryDateArr[1]+"-"+deliveryDateArr[0];
	}
    if(orderdate != null && ordertime != null && orderdate != '' && ordertime != '') {
		deliveryDate = orderdate;
		deliveryTime = ordertime;
	}		
    const uri = apiUrlV2+'outlets/getZoneDetails?app_id='+appId+"&availability="+deliveryId+"&outletId="+outletId+"&ZoneId="+zoneId + "&orderdate=" + deliveryDate + "&ordertime=" + deliveryTime;
    const result = yield call(Axios.get, uri);
	var resultArr = [];
	resultArr.push(result.data);
    yield put({ type: SET_ZONE_DETAIL, value: resultArr });
  } 
  catch {
    console.log('Get All Outlets Failed');
  }
} 
