import { takeEvery, call, put } from 'redux-saga/effects';
import { GET_CATERING_PRODCUTLIST, SET_CATERING_PRODCUTLIST } from '../actions';
import { appId, apiUrlV2 } from "../components/Helpers/Config";
import Axios from 'axios';

export const watchGetCateringProduct = function* () {
  yield takeEvery(GET_CATERING_PRODCUTLIST, workerGetCateringProduct);
}

function* workerGetCateringProduct({ proSlug }) {
  try {
	var proSlugTxt = "&category_id=" + proSlug['category_id'] + "&catering_type=" + proSlug['catering_type'] + "&catering_outlet_id=" + proSlug['catering_outlet_id'] + "&catering_hall_id=";  
    const uri = apiUrlV2+'catering/cateringprodcutlist?app_id='+appId+proSlugTxt;
    const result = yield call(Axios.get, uri);
	var resultArr = [];
	resultArr.push(result.data);
    yield put({ type: SET_CATERING_PRODCUTLIST, value: resultArr });
  } 
  catch {
    console.log('Get Catering Product Failed');
  }
}
