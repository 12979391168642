/* eslint-disable */
import React, { Component } from "react";
import cookie from "react-cookies";
import axios from "axios";
import { connect } from "react-redux";
import Slider from "react-slick";

import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import HomeBanner from "./HomeSubCompo/HomeBanner";
import FeaturedProducts from "./HomeSubCompo/FeaturedProducts";
import PromotionProducts from "./HomeSubCompo/PromotionProducts";
import ProductDetail from "../Products/ProductDetail";

import {
  appId,
  appName,
  apiUrl,
  deliveryId,
  pickupId,
  cateringId,
  reservationId,
  baseUrl,
} from "../Helpers/Config";
import {
  GET_GLOBAL_SETTINGS,
  GET_STATIC_BLOCK,
  GET_NORMAL_POPUP,
  GET_CATEGORY_LIST,
  GET_MENU_NAVIGATION,
  GET_TESTIMONIALS,
} from "../../actions";
import {
  showLoader,
  hideLoader,
  validateEmailFun,
  showCustomAlert,
  stripslashes,
} from "../Helpers/SettingHelper";

import newletterImg from "../../common/images/newletter-bg.jpg";
import profileImg from "../../common/images/testi-profile.png";
import promobigImg from "../../common/images/promo-place.jpg";
import promoImg from "../../common/images/product.jpg";
import yscooterImg from "../../common/images/yellow-scooter.png";
import ytakeImg from "../../common/images/yellow-takeaway.png";
import ycateringImg from "../../common/images/yellow-catering.png";

import homepopup from "../../common/images/popup-bg.jpg";

import deliverypopImg from "../../common/images/Delivery_white.svg";

import takeawaypopImg from "../../common/images/Takeaway_white.svg";

import cateringImg from "../../common/images/catering.svg";

import ninjaapopImg from "../../common/images/popupninjaos.png";

import OwlCarousel from "react-owl-carousel2";
import "../../common/css/owl.carousel.css";
var Parser = require("html-react-parser");
const options = {
  items: 3,
  autoplay: false,
  autoWidth: true,
  margin: 15,
  loop: true,
};

var settingsGallery1 = {
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  centerMode: true,
  className: "center",
  centerPadding: "0",
  responsive: [
    {
      breakpoint: 1191,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: false,
      },
    },
    {
      breakpoint: 850,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 680,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

class Home extends Component {
  constructor(props) {
    super(props);

    var normalpopupFlg = "initial";
    if (
      (cookie.load("triggerAvlPop") != "" &&
        cookie.load("triggerAvlPop") != undefined) ||
      cookie.load("orderPopuptrigger") === "Yes" ||
      cookie.load("loginpopupTrigger") === "Yes" ||
      cookie.load("promoPopupTrigger") === "Yes"
    ) {
      normalpopupFlg = "trigger";
    }

    this.state = {
      users: [],
      nextavail: "",
      cartTriggerFlg: "No",
      staticblacks: [],
      testimonials: [],
      testimonialpath: [],
      testimonialsBlks: "",
      doorStepBlck: "",
      ourStoryBlck: "",
      viewProductFlg: "no",
      viewProductSlug: "",
      normalpopuplist: [],
      normalpopupdata: [],
      normalpopup_status: "no",
      normalpopup_flg: normalpopupFlg,
      normalpopupstatus: "initial",
      promo_mail_id: "",
      displayType: "all",
    };

    this.props.getSettings();
    this.props.getCategoryList();
    this.props.getMenuNavigation();
    this.props.getNormalPopup();
    this.props.getStaticBlock();
    this.props.getTestimonials();
    if (window.location.pathname == "/index.html") {
      window.location.href = "/";
    }
  }

  componentWillReceiveProps(PropsData) {
    if (PropsData.staticblack !== this.state.staticblacks) {
      var testimonials = "",
        doorStepBlock = "",
        ourStoryBlock = "";
      if (Object.keys(PropsData.staticblack).length > 0) {
        PropsData.staticblack.map((data, index) => {
          if (data.staticblocks_slug === "testimonials") {
            testimonials = data.staticblocks_description;
            return "";
          }
          if (data.staticblocks_slug === "door-step-block") {
            doorStepBlock = data.staticblocks_description;
            return "";
          }
          if (data.staticblocks_slug === "our-story-block") {
            ourStoryBlock = data.staticblocks_description;
            return "";
          }
        });
      }
      testimonials =
        testimonials !== "" && testimonials !== null
          ? Parser(testimonials)
          : testimonials;
      doorStepBlock =
        doorStepBlock !== "" && doorStepBlock !== null
          ? Parser(doorStepBlock)
          : doorStepBlock;
      ourStoryBlock =
        ourStoryBlock !== "" && ourStoryBlock !== null
          ? Parser(ourStoryBlock)
          : ourStoryBlock;
      this.setState({
        staticblacks: PropsData.staticblack,
        testimonialsBlks: testimonials,
        doorStepBlck: doorStepBlock,
        ourStoryBlck: ourStoryBlock,
      });
    }

    if (
      PropsData.testimonials !== undefined &&
      PropsData.testimonials !== this.state.testimonials
    ) {
      this.setState({
        testimonials: PropsData.testimonials,
        testimonialpath: PropsData.testimonialpath,
      });
    }

    if (
      PropsData.normalpopuplist !== this.state.normalpopuplist &&
      this.state.normalpopup_flg === "initial"
    ) {
      var normalpopupMain = PropsData.normalpopuplist,
        normalpopupdata = [],
        normalpopupStatus = "no";
      if (normalpopupMain !== "") {
        if (Object.keys(normalpopupMain).length > 0) {
          var normalpopupIds = cookie.load("normalpopupIds");
          var normalpopupIdArr =
            normalpopupIds != "" && normalpopupIds != undefined
              ? normalpopupIds.split("~~")
              : Array();
          if (
            $.inArray(normalpopupMain[0].normalpopup_id, normalpopupIdArr) ===
            -1
          ) {
            normalpopupdata = normalpopupMain[0];
            normalpopupStatus = "yes";
          }
          this.setState({
            normalpopuplist: normalpopupMain,
            normalpopupdata: normalpopupdata,
            normalpopup_status: normalpopupStatus,
            normalpopup_flg: "trigger",
          });
        }
      }
    }

    if (PropsData.normalpopupstatus !== this.state.normalpopupstatus) {
      this.setState({ normalpopupstatus: PropsData.normalpopupstatus });
    }

    if (PropsData.globalsettings !== this.props.globalsettings) {
      var settingsArrList = PropsData.globalsettings[0];
      if (Object.keys(settingsArrList).length > 0) {
        if (settingsArrList.status === "ok") {
          cookie.save(
            "fb_pixel_id",
            settingsArrList.client_fb_pixel_id !== null
              ? settingsArrList.result_set.client_fb_pixel_id
              : ""
          );
          cookie.save(
            "ga_id",
            settingsArrList.client_google_ga_id !== null
              ? settingsArrList.result_set.client_google_ga_id
              : ""
          );
          cookie.save(
            "gtm_id",
            settingsArrList.client_google_gtm_id !== null
              ? settingsArrList.result_set.client_google_gtm_id
              : ""
          );
        }
      }
    }
  }

  chooseAvailability(availability) {
    return false;
    var defaultAvilTy = cookie.load("defaultAvilablityId");
    if (defaultAvilTy !== availability) {
      var cartTotalItems = cookie.load("cartTotalItems");
      cartTotalItems =
        cartTotalItems != "" && cartTotalItems != undefined
          ? parseInt(cartTotalItems)
          : 0;
      if (defaultAvilTy === cateringId && cartTotalItems > 0) {
        this.setState({ nextavail: availability });
        $.magnificPopup.open({
          items: {
            src: "#warning-popup",
          },
          type: "inline",
        });
        return false;
      } else if (
        cookie.load("orderOutletId") != "" &&
        cookie.load("orderOutletId") != undefined
      ) {
        this.setState({ nextavail: availability });
        $.magnificPopup.open({
          items: {
            src: "#warning-popup",
          },
          type: "inline",
        });
        return false;
      }
    }

    var popupIdtxt = "";
    if (availability === deliveryId) {
      popupIdtxt = "#delevery-popup";
    } else if (availability === pickupId) {
      popupIdtxt = "#takeaway-popup";
    } else if (availability === cateringId) {
      cookie.save("defaultAvilablityId", cateringId, { path: "/" });
      $.magnificPopup.close();
      this.props.history.push("/catering");
      return false;
    } else if (availability === reservationId) {
      /*cookie.save("defaultAvilablityId", reservationId, { path: "/" });
      $.magnificPopup.close();
      this.props.history.push("/reservation");*/
      return false;
      /* popupIdtxt = '#comingsoon-popup';*/
    }

    if (popupIdtxt !== "") {
      $.magnificPopup.open({
        items: {
          src: popupIdtxt,
        },
        type: "inline",
      });
    }
  }

  chooseAvailabilityEnvtFun(availability, event) {
    event.preventDefault();
    var defaultAvilTy = cookie.load("defaultAvilablityId");
    if (defaultAvilTy !== availability) {
      var cartTotalItems = cookie.load("cartTotalItems");
      cartTotalItems =
        cartTotalItems != "" && cartTotalItems != undefined
          ? parseInt(cartTotalItems)
          : 0;
      /*  && cartTotalItems > 0 */
      if (defaultAvilTy === cateringId) {
        this.setState({ nextavail: availability });
        $.magnificPopup.open({
          items: {
            src: "#warning-popup",
          },
          type: "inline",
        });
        return false;
      } else if (
        cookie.load("orderOutletId") != "" &&
        cookie.load("orderOutletId") != undefined
      ) {
        this.setState({ nextavail: availability });
        $.magnificPopup.open({
          items: {
            src: "#warning-popup",
          },
          type: "inline",
        });
        return false;
      }
    } else if (
      defaultAvilTy === availability &&
      cookie.load("orderOutletId") != "" &&
      cookie.load("orderOutletId") != undefined
    ) {
      /*$.magnificPopup.close();
      this.props.history.push('/products');
      return false;*/
    }

    var popupIdtxt = "";
    if (availability === deliveryId) {
      $(".delivery_outletpoup").find(".outlet_error").html("");
      popupIdtxt = "#delevery-popup";
    } else if (availability === pickupId) {
      popupIdtxt = "#takeaway-popup";
    } else if (availability === cateringId) {
      cookie.save("defaultAvilablityId", cateringId, { path: "/" });
      $.magnificPopup.close();
      this.props.history.push("/catering");
      return false;
    } else if (availability === reservationId) {
      /*cookie.save("defaultAvilablityId", reservationId, { path: "/" });
      $.magnificPopup.close();
      this.props.history.push("/reservation");*/
      return false;
      /*popupIdtxt = '#comingsoon-popup';*/
    }

    if (popupIdtxt !== "") {
      $.magnificPopup.open({
        items: {
          src: popupIdtxt,
        },
        type: "inline",
      });
    }
  }

  checkActiveDivHm(avlType) {
    var clsTxt = "";
    var availability = cookie.load("defaultAvilablityId");
    var orderOutletId = cookie.load("orderOutletId");
    if (
      availability == avlType &&
      orderOutletId !== "" &&
      orderOutletId !== undefined
    ) {
      clsTxt += "active";
    } else if (
      availability == avlType &&
      (avlType === cateringId || avlType === reservationId)
    ) {
      clsTxt += "active";
    }
    return clsTxt;
  }

  componentDidMount() {
    $(document).find("body").addClass("homepopupopen");

    if (
      cookie.load("outletchosen") == "" ||
      typeof cookie.load("outletchosen") == "undefined"
    ) {
      // $('.homepopup').modal("show");
    }

    if (
      cookie.load("triggerAvlPop") != "" &&
      cookie.load("triggerAvlPop") != undefined
    ) {
      var availabilityId = cookie.load("triggerAvlPop");
      cookie.remove("triggerAvlPop");
      this.chooseAvailability(availabilityId);
    }

    if (cookie.load("orderPopuptrigger") === "Yes") {
      cookie.remove("orderPopuptrigger");
      $.magnificPopup.open({
        items: {
          src: "#order-popup",
        },
        type: "inline",
      });
    }

    if (cookie.load("loginpopupTrigger") === "Yes") {
      cookie.save("loginpopupTrigger", "fromcheckout", { path: "/" });
      $.magnificPopup.open({
        items: {
          src: "#login-popup",
        },
        type: "inline",
      });
    }

    var RctThis = this;
    $("body")
      .off("click", ".act_order_popup")
      .on("click", ".act_order_popup", function (e) {
        e.preventDefault();

        var odrType = $(this).attr("data-acturl");
        var availabilityId = "";
        if (odrType === "delivery") {
          availabilityId = deliveryId;
        } else if (odrType === "pickup") {
          availabilityId = pickupId;
        } else if (odrType === "catering") {
          availabilityId = cateringId;
        } else if (odrType === "reservation") {
          availabilityId = reservationId;
        }

        if (availabilityId !== "") {
          RctThis.chooseAvailability(availabilityId);
        } else if (availabilityId === "" && odrType === "ordernow") {
          $.magnificPopup.open({
            items: {
              src: "#order-popup",
            },
            type: "inline",
          });
        }
      });
  }

  closeHomepop() {
    $.magnificPopup.close();
    $("#order-popup").fadeOut(450);
    cookie.save("skipbrowse", "Yes", {
      path: "/",
    });
    setTimeout(function () {
      $("#order-popup").modal("hide");
    }, 800);
    this.props.history.push("/products");
  }

  sateValChange = (field, value) => {
    if (field === "cartflg") {
      this.setState({ cartTriggerFlg: value });
    }
    if (field === "view_pro_data" && value !== "") {
      this.setState(
        { viewProductFlg: "yes", viewProductSlug: value },
        function () {
          this.openProDetailPopup();
        }.bind(this)
      );
    }
    if (field === "view_pro_upate" && value !== "") {
      this.setState({ viewProductFlg: value });
    }
    if (field === "firstcategory" && value !== "") {
      this.setState({ firstcategory: value });
    }
  };

  openProDetailPopup() {
    showLoader("comboPro-" + this.state.viewProductSlug, "Idtext");
    $("#ProductDetailMdl").modal({ backdrop: "static", keyboard: false });
  }

  triggerNormalPopup(trgType) {
    var otherPageActTrigger = "no";
    if (
      (cookie.load("triggerAvlPop") != "" &&
        cookie.load("triggerAvlPop") != undefined) ||
      cookie.load("orderPopuptrigger") === "Yes" ||
      cookie.load("loginpopupTrigger") === "Yes"
    ) {
      otherPageActTrigger = "yes";
    }

    if (
      trgType === "loading" &&
      otherPageActTrigger === "no" &&
      this.state.normalpopup_status === "yes" &&
      Object.keys(this.state.normalpopupdata).length > 0 &&
      cookie.load("promoPopupTrigger") !== "Yes"
    ) {
      var normalpopupIds = cookie.load("normalpopupIds");
      var normalpopupIdsNew =
        normalpopupIds != "" && normalpopupIds != undefined
          ? normalpopupIds + "~~" + this.state.normalpopupdata.normalpopup_id
          : this.state.normalpopupdata.normalpopup_id;
      var normalpopupIdArr = [];
      normalpopupIdArr["normalpopupids"] = normalpopupIdsNew;
      cookie.save("normalpopupIds", normalpopupIdsNew, { path: "/" });
      var $_this_rec = this;
      $.magnificPopup.open({
        items: {
          src: "#normal-popup",
        },
        type: "inline",
        midClick: true,
        closeOnBgClick: false,
        callbacks: {
          close: function () {
            $_this_rec.normalPopupUpdate();
          },
        },
      });
    }

    if (
      (cookie.load("promoPopupTrigger") === "Yes" ||
        (otherPageActTrigger === "no" &&
          this.state.normalpopup_status === "no" &&
          Object.keys(this.state.normalpopupdata).length === 0)) &&
      cookie.load("mailpopopuptrg") !== "yes"
    ) {
      cookie.save("mailpopopuptrg", "yes", { path: "/" });
      cookie.remove("promoPopupTrigger");
      $.magnificPopup.open({
        items: {
          src: "#promo-check-popup",
        },
        type: "inline",
        midClick: true,
        closeOnBgClick: false,
      });
    }
  }

  normalPopupUpdate() {
    if (cookie.load("mailpopopuptrg") !== "yes") {
      this.props.history.push("/refpage/promopopup");
    }
  }

  handleEmailChange(event) {
    this.setState({ promo_mail_id: event.target.value, promomail_error: "" });
  }

  sendPromoMailFun() {
    var promoEmail = this.state.promo_mail_id;

    var mailErrorTxt = "";
    if (promoEmail === "") {
      mailErrorTxt = "Email Address is required.";
    } else if (!validateEmailFun(promoEmail)) {
      mailErrorTxt = "Invalide Email Address";
    }

    if (mailErrorTxt !== "") {
      this.setState({ promomail_error: mailErrorTxt });
      return false;
    } else {
      showLoader("promomailpopup-cls", "class");

      var qs = require("qs");
      var postObject = {
        app_id: appId,
        email_address: promoEmail,
      };

      axios
        .post(apiUrl + "promotion/user_promotion", qs.stringify(postObject))
        .then((response) => {
          hideLoader("promomailpopup-cls", "class");
          $.magnificPopup.close();
          if (response.data.status === "ok") {
            showCustomAlert(
              "success",
              "You are now a Member of Ajmir Briyani. Please check your email for more information."
            );
          } else {
            var errMsgtxt =
              response.data.message !== ""
                ? response.data.message
                : "Sorry! You didn`t have promo code.";
            showCustomAlert("error", errMsgtxt);
          }
          return false;
        });
    }

    return false;
  }

  setdisplayType(displayType) {
    this.setState({ displayType: displayType });
  }

  chooseAvailabilityFun(availability, event) {
    this.closeHomepop();
    event.preventDefault();
    var defaultAvilTy = cookie.load("defaultAvilablityId");
    if (defaultAvilTy !== availability) {
      var cartTotalItems = cookie.load("cartTotalItems");
      cartTotalItems =
        cartTotalItems != "" && cartTotalItems != undefined
          ? parseInt(cartTotalItems)
          : 0;
      /*  && cartTotalItems > 0 */
      if (defaultAvilTy === cateringId) {
        this.setState({ nextavail: availability });
        $.magnificPopup.open({
          items: {
            src: "#warning-popup",
          },
          type: "inline",
        });
        return false;
      } else if (
        cookie.load("orderOutletId") != "" &&
        cookie.load("orderOutletId") != undefined
      ) {
        this.setState({ nextavail: availability });
        $.magnificPopup.open({
          items: {
            src: "#warning-popup",
          },
          type: "inline",
        });
        return false;
      }
    } else if (
      defaultAvilTy === availability &&
      cookie.load("orderOutletId") != "" &&
      cookie.load("orderOutletId") != undefined
    ) {
      /*$.magnificPopup.close();
      this.props.history.push('/products');
      return false;*/
    }

    var popupIdtxt = "";
    if (availability === deliveryId) {
      $(".delivery_outletpoup").find(".outlet_error").html("");
      popupIdtxt = "#delevery-popup";
    } else if (availability === pickupId) {
      popupIdtxt = "#takeaway-popup";
    } else if (availability === cateringId) {
      cookie.save("defaultAvilablityId", cateringId, { path: "/" });
      $.magnificPopup.close();
      this.props.history.push("/catering");
      return false;
    } else if (availability === reservationId) {
      /*cookie.save("defaultAvilablityId", reservationId, { path: "/" });
      $.magnificPopup.close();
      this.props.history.push("/reservation");*/
      return false;
      /*popupIdtxt = '#comingsoon-popup';*/
    }

    if (popupIdtxt !== "") {
      $.magnificPopup.open({
        items: {
          src: popupIdtxt,
        },
        type: "inline",
      });
    }
  }

  render() {
    let settingsArr = this.props.globalsettings;
    var showPromoPopup = "",
      showNormalPopup = "";
    if (Object.keys(settingsArr).length > 0) {
      if (Object.keys(settingsArr[0].result_set).length > 0) {
        if (settingsArr[0].result_set.client_promocode_enable === "1") {
          showNormalPopup =
            settingsArr[0].result_set.client_promo_code_normal_popup_enable ===
            "1"
              ? "yes"
              : "";
          showPromoPopup =
            settingsArr[0].result_set.client_promocode_options === "1"
              ? "yes"
              : "";
        }
      }
    }
    var Testimonial = {
      centerMode: true,
      slidesToShow: 3,
      centerPadding: "0px",
      dots: false,
      arrows: true,
      responsive: [
        {
          breakpoint: 900,
          settings: {
            arrows: true,
            centerMode: true,
            centerPadding: "0px",
            slidesToShow: 1,
          },
        },
      ],
    };
    return (
      <>
        <div className="home-main-div">
          {/* Header section */}
          <Header
            homePageState={this.state}
            cartTriggerFlg={this.state.cartTriggerFlg}
            sateValChange={this.sateValChange}
          />

          {/* Home banner section */}
          <HomeBanner />

          <section className="home-choose-type">
            <div className="container">
              <ul>
                <li className={this.checkActiveDivHm(deliveryId)}>
                  <div className="hct-img">
                    <img src={yscooterImg} />
                  </div>
                  <div className="hct-content">
                    <h2>Delivery</h2>
                    <a
                      href="/"
                      onClick={this.chooseAvailabilityEnvtFun.bind(
                        this,
                        deliveryId
                      )}
                      className="button"
                    >
                      Order Now
                    </a>
                  </div>
                </li>
                <li className={this.checkActiveDivHm(pickupId)}>
                  <div className="hct-img">
                    <img src={ytakeImg} />
                  </div>
                  <div className="hct-content">
                    <h2>Takeaway</h2>
                    <a
                      href="/"
                      onClick={this.chooseAvailabilityEnvtFun.bind(
                        this,
                        pickupId
                      )}
                      className="button"
                    >
                      Order Now
                    </a>
                  </div>
                </li>
                {cookie.load("catering_availability_enabled") === "1" && (
                  <li className={this.checkActiveDivHm(cateringId)}>
                    <div className="hct-img">
                      <img src={ycateringImg} />
                    </div>
                    <div className="hct-content">
                      <h2>Catering</h2>
                      {cookie.load("catering_availability_enabled") == "1" ? (
                        <a
                          href="/"
                          onClick={this.chooseAvailabilityEnvtFun.bind(
                            this,
                            cateringId
                          )}
                          className="button disbl_href_action"
                        >
                          Order Now
                        </a>
                      ) : (
                        <a
                          href="/"
                          onClick={this.chooseAvailabilityEnvtFun.bind(
                            this,
                            cateringId
                          )}
                          className="button disbl_href_action"
                        >
                          Order Now
                        </a>
                      )}
                    </div>
                  </li>
                )}
              </ul>
            </div>
          </section>
          <div className="modal-skip-div">
            <a
              className="modal-skip"
              onClick={this.closeHomepop.bind(this)}
              style={{ cursor: "pointer" }}
            >
              Skip &amp; Browse
            </a>
          </div>

          <FeaturedProducts sateValChange={this.sateValChange} />

          <PromotionProducts sateValChange={this.sateValChange} />

          {Object.keys(this.state.testimonials).length > 0 && (
            <section className="home-testimonials">
              <div className="container">
                <>
                  <div className="testimonials-title-text">
                    <h2>WHAT THEY SAID</h2>
                  </div>

                  <Slider {...Testimonial}>
                    {Object.keys(this.state.testimonials).length &&
                      this.state.testimonials.map((testimonial, index) => {
                        return (
                          <div key={index + 1000}>
                            <div className="inner-testimonials">
                              <div className="testi-content">
                                <p>
                                  {stripslashes(
                                    testimonial.testimonial_description
                                  )}
                                </p>
                              </div>
                              <div className="testi-author">
                                <div className="testi-author-img">
                                  <img
                                    src={
                                      this.state.testimonialpath.image_source +
                                      testimonial.testimonial_image
                                    }
                                  />
                                </div>
                                <div className="testi-author-name">
                                  <h4>{testimonial.testimonial_tittle}</h4>
                                  <span>{testimonial.testimonial_company}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </Slider>
                </>
              </div>
            </section>
          )}

          {/* Footer section */}
          <Footer appName={appName} />

          <ProductDetail
            productState={this.state}
            sateValChange={this.sateValChange}
            classNameAdd="modal fade modal-dialog-tgrfromhome"
          />

          {/*Normal popup Start*/}
          <div
            id="normal-popup"
            className="white-popup mfp-hide popup_sec normal-popup"
          >
            <div className="normal_popup_alert">
              {this.state.normalpopup_status === "yes" &&
                Object.keys(this.state.normalpopupdata).length > 0 && (
                  <div className="normal_popup_cont">
                    {Parser(this.state.normalpopupdata.normalpopup_description)}
                  </div>
                )}
            </div>
          </div>
          {/*Normal popup - End*/}

          {/*Promo Check Email popup Start*/}
          <div
            id="promo-check-popup"
            className="white-popup mfp-hide popup_sec promo-check-popup"
          >
            <div className="promopopup-maindiv">
              <div className="promopopup-lhs">
                <div className="frm-action-div">
                  <div className="frm-top-title">
                    <h3>Join our email list</h3>
                    <p>Enter your Email address to receive your promocode</p>
                  </div>

                  <div className="frm-inputbtn-div">
                    <div className="form-group">
                      <div className="focus-out">
                        <label>Email Address</label>
                        <input
                          type="email"
                          className="form-control input-focus"
                          value={this.state.promo_mail_id}
                          onChange={this.handleEmailChange.bind(this)}
                        />
                        {this.state.promomail_error !== "" && (
                          <div id="promomail-error">
                            {this.state.promomail_error}
                          </div>
                        )}
                      </div>
                    </div>

                    <button
                      type="button"
                      onClick={this.sendPromoMailFun.bind(this)}
                      className="button promomailpopup-cls"
                    >
                      Subscribe
                    </button>
                  </div>

                  <div className="frm-bottom-text">
                    <p>
                      SIGN UP FOR EXCLUSIVE UPDATES, NEW PRODUCTS, AND
                      INSIDER-ONLY DISCOUNTS
                    </p>
                  </div>
                </div>
              </div>

              <div className="promopopup-rhs">
                <img src={newletterImg} />
              </div>
            </div>
          </div>
          {/*Promo Check Email popup - End*/}

          {this.state.normalpopupstatus !== "initial" &&
            (showNormalPopup === "yes" || showPromoPopup === "yes") &&
            this.triggerNormalPopup("loading")}
        </div>

        <div
          className="modal homepopup"
          id="onload-screen"
          data-keyboard="false"
          data-backdrop="static"
        >
          <div className="modal-dialog index-modal">
            <img className="bg-img" src={homepopup} />
            <div className="modal-sec">
              <div className="modal-content">
                <div className="modal-logo">
                  <img
                    alt="Ninja OS System"
                    src={baseUrl + "/static/media/logo.cdb4bcd7.png"}
                    className="img-responsive"
                  />
                </div>
                <div className="modal-body">
                  <h3 className="modal-title">
                    How will you be enjoying
                    <br />
                    your food today?
                  </h3>
                  <div className="modal-button">
                    <button
                      className="btn in-active"
                      onClick={this.chooseAvailabilityFun.bind(
                        this,
                        deliveryId
                      )}
                      type="button"
                      id="delivery"
                      name="delivery"
                    >
                      <img
                        alt=""
                        src={deliverypopImg}
                        className="icon-img-small1"
                      />
                      Delivery
                    </button>
                  </div>
                  <div className="modal-button take-button">
                    <button
                      className="btn active"
                      type="button"
                      onClick={this.chooseAvailabilityFun.bind(this, pickupId)}
                      id="takeaway"
                      name="takeaway"
                    >
                      <img
                        alt=""
                        src={takeawaypopImg}
                        className="icon-img-small1"
                      />
                      Takeaway
                    </button>
                  </div>
                  <a
                    className="modal-skip"
                    onClick={this.closeHomepop.bind(this)}
                    style={{ cursor: "pointer" }}
                  >
                    Skip &amp; Browse
                  </a>
                </div>
                <div className="modal-footer">
                  <div className="powered-by">POWERED BY</div>
                  <div className="footer-logo">
                    <div>
                      <img src={ninjaapopImg} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateTopProps = (state) => {
  var blacksArr = Array();
  if (Object.keys(state.staticblack).length > 0) {
    if (state.staticblack[0].status === "ok") {
      blacksArr = state.staticblack[0].result_set;
    }
  }

  var normalPopupStatus = "initial";
  var normalPopupArr = Array();
  if (Object.keys(state.normalpopuplist).length > 0) {
    if (state.normalpopuplist[0].status === "ok") {
      normalPopupArr = state.normalpopuplist[0].result_set;
      normalPopupStatus = "ok";
    } else {
      normalPopupStatus = "error";
    }
  }

  var tempArr = Array();
  var navigateRst = Array();
  var navigateCmn = Array();
  if (Object.keys(state.product).length > 0) {
    var tempArr = !("menuNavigation" in state.product[0])
      ? Array()
      : state.product[0].menuNavigation;
    if (Object.keys(tempArr).length > 0) {
      if (tempArr[0].status === "ok") {
        navigateRst = tempArr[0].result_set;
        navigateCmn = tempArr[0].common;
      }
    }
  }

  var testimonials = Array();
  var testimonialpath = Array();
  if (Object.keys(state.testimonials).length > 0) {
    testimonials = state.testimonials[0].result_set;
    testimonialpath = state.testimonials[0].common;
  }

  return {
    globalsettings: state.settings,
    categorylist: state.categorylist,
    navigateMenu: navigateRst,
    navigateCommon: navigateCmn,
    staticblack: blacksArr,
    normalpopuplist: normalPopupArr,
    testimonials: testimonials,
    testimonialpath: testimonialpath,
    normalpopupstatus: normalPopupStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSettings: () => {
      dispatch({ type: GET_GLOBAL_SETTINGS });
    },
    getCategoryList: () => {
      dispatch({ type: GET_CATEGORY_LIST });
    },
    getStaticBlock: () => {
      dispatch({ type: GET_STATIC_BLOCK });
    },
    getNormalPopup: () => {
      dispatch({ type: GET_NORMAL_POPUP });
    },
    getMenuNavigation: () => {
      dispatch({ type: GET_MENU_NAVIGATION });
    },
    getTestimonials: () => {
      dispatch({ type: GET_TESTIMONIALS });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Home);
