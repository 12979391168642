/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Axios from "axios";
import cookie from "react-cookies";
import {
  appId,
  apiUrl,
  apiUrlV2,
  deliveryId,
  timThumpUrl,
} from "../../Helpers/Config";
import {
  getReferenceID,
  stripslashes,
  showPriceValue,
  callImage,
  showLoader,
  hideLoader,
  showCustomAlert,
  showCartLst,
  showAlert,
  removePromoCkValue,
} from "../../Helpers/SettingHelper";
import noimage from "../../../common/images/no-img-product.png";
import heartWhite from "../../../common/images/heart-white.png";
import heartYellow from "../../../common/images/heart-yellow.png";
import { setMinutes, setHours, getDay, format } from "date-fns";
var qs = require("qs");

import { GET_FEATUREPRO } from "../../../actions";

var Parser = require("html-react-parser");

class FeaturedProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
    };
  }

  componentWillMount() {
    this.props.getFeatureProList();
  }

  proQtyAction(indxFlg, actionFlg) {
    var proqtyInput = $("#proIndex-" + indxFlg)
      .find(".proqty_input")
      .val();
    proqtyInput = parseInt(proqtyInput);
    if (actionFlg === "decr") {
      proqtyInput = proqtyInput > 1 ? proqtyInput - 1 : proqtyInput;
    } else {
      proqtyInput = proqtyInput + 1;
    }
    $("#proIndex-" + indxFlg)
      .find(".proqty_input")
      .val(proqtyInput);
  }

  /* add to cart */
  addToCartSimple(productDetail, actionFlg, event) {
    event.preventDefault();
    var avilablityId = cookie.load("defaultAvilablityId");
    var orderOutletId = cookie.load("orderOutletId");
    if (
      avilablityId !== "" &&
      orderOutletId != undefined &&
      orderOutletId !== "" &&
      orderOutletId != undefined
    ) {
      var IndexFlg = productDetail.product_primary_id;

      if (actionFlg === "initial") {
        $("#proIndex-" + IndexFlg).addClass("active");
        $("#proIndex-" + IndexFlg)
          .find(".smiple_product_lk")
          .hide();
        $("#proIndex-" + IndexFlg)
          .find(".addcart_done_maindiv")
          .show();
      } else {
        showLoader("proIndex-" + IndexFlg, "Idtext");
        var availabilityId = cookie.load("defaultAvilablityId");
        var availabilityName =
          availabilityId === deliveryId ? "Delivery" : "Pickup";
        var isAddonProduct = "No";
        var productId = productDetail.product_id;
        var customerId =
          typeof cookie.load("UserId") === "undefined"
            ? ""
            : cookie.load("UserId");
        var proqtyQty = $("#proIndex-" + IndexFlg)
          .find(".proqty_input")
          .val();

        var postObject = {};
        postObject = {
          app_id: appId,
          product_id: productId,
          product_qty: proqtyQty,
          product_type: 1,
          availability_id: availabilityId,
          availability_name: availabilityName,
          isAddonProduct: isAddonProduct,
          reference_id: customerId === "" ? getReferenceID() : "",
          customer_id: customerId,
        };

        Axios.post(
          apiUrlV2 + "cart/simpleCartInsert",
          qs.stringify(postObject)
        ).then((res) => {
          $("#proIndex-" + IndexFlg).removeClass("active");
          hideLoader("proIndex-" + IndexFlg, "Idtext");
          $("#proIndex-" + IndexFlg)
            .find(".addcart_done_maindiv")
            .hide();
          $("#proIndex-" + IndexFlg)
            .find(".smiple_product_lk")
            .show();
          if (res.data.status === "ok") {
            this.setState({compoinput_value: 1});
            this.props.sateValChange("cartflg", "yes");
            showCustomAlert(
              "success",
              "Great choice! Item added to your cart."
            );
            /*showCartLst();*/
            removePromoCkValue();
            this.handleShowAlertFun(
              "success",
              "Great choice! Item added to your cart."
            );
            return false;
          } else if (res.data.status === "error") {
            this.setState({compoinput_value: 1});
            var errMsgtxt =
              res.data.message !== ""
                ? res.data.message
                : "Sorry! Products can`t add your cart.";
            showCustomAlert("error", errMsgtxt);
          }
        });
      }
    } else {
      cookie.save("popuptriggerFrom", "FeaturedPro", { path: "/" });
      $.magnificPopup.open({
        items: {
          src: ".order_popup",
        },
        type: "inline",
      });
    }
  }

  handleShowAlertFun(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  getProductSplPrice(productDetail) {
    var productSpecialPrice =
      productDetail.product_price_old !== ""
        ? parseFloat(productDetail.product_price_old)
        : 0;
    if (
      productSpecialPrice > 0
    ) {
        return (
          <>
          <div className="product-spl-price">
              <h3>{showPriceValue(productDetail.product_price_old)}</h3>
          </div>
          <div className="product-price">
              <h3>{showPriceValue(productDetail.product_price)}</h3>
          </div>
          </>
        );
    }else{
        return (
            <div className="product-price">
              <h3>{showPriceValue(productDetail.product_price)}</h3>
            </div>
        );
      }
  }

  getFavActivCls(product) {
    var favActCls = "disbl_href_action favour-icon-link";
    if (product.fav_product_primary_id === product.product_primary_id) {
      favActCls = favActCls + " active";
    }

    return favActCls;
  }

  favourActFun(productDetail, event) {
    event.preventDefault();
    var productPrimaryId = productDetail.product_primary_id;

    var actionFlgText = "Yes";
    if (
      productDetail.fav_product_primary_id === productDetail.product_primary_id
    ) {
      actionFlgText = "No";
    }

    var postObject = {};
    postObject = {
      app_id: appId,
      outlet_id: cookie.load("orderOutletId"),
      product_id: productPrimaryId,
      fav_flag: actionFlgText,
      availability_id: cookie.load("defaultAvilablityId"),
      customer_id: cookie.load("UserId"),
    };

    var succesFlgTxt =
      actionFlgText === "Yes"
        ? "Great choice! Item added to your favourite list."
        : "Item was removed to your favourite list.";
    var errorFlgTxt =
      actionFlgText === "Yes"
        ? "Sorry! Products can`t add your favourite list."
        : "Sorry! Products can`t remove your favourite list.";

    Axios.post(
      apiUrl + "products/favourite_products_add",
      qs.stringify(postObject)
    ).then((res) => {
      if (res.data.status === "ok") {
        this.handleShowAlertFun("success", succesFlgTxt);
      } else if (res.data.status === "error") {
        var errMsgtxt =
          res.data.message !== "" ? res.data.message : errorFlgTxt;
        this.handleShowAlertFun("Error", errMsgtxt);
      }
      this.props.sateValChange("favTgr", "Yes");
      return false;
    });
  }

  loadMoreFunct(event) {
    console.log("okokok");
    event.preventDefault();
    var selectedrwsVal = $(".products-list-ulmain").attr("data-selectedrws");
    var totalrowcuntVal = $(".products-list-ulmain").attr("data-totalrowcunt");

    selectedrwsVal = parseInt(selectedrwsVal) + 4;

    $(".products-list-ulmain").attr("data-selectedrws", selectedrwsVal);
    console.log("selectedrwsVal", selectedrwsVal);
    $(".products-single-li").each(function (index) {
      if (index <= selectedrwsVal) {
        $(this).removeClass("hide-cel-cls");
      }
    });
  }

  viewProDetail(productDetail, event) {
    event.preventDefault();
    var avilablityId = cookie.load("defaultAvilablityId");
    var orderOutletId = cookie.load("orderOutletId");
    if (
      avilablityId !== "" &&
      orderOutletId != undefined &&
      orderOutletId !== "" &&
      orderOutletId != undefined
    ) {
      var productSlug = productDetail.product_slug;
      if (productSlug !== "") {
        $("#proIndex-" + productDetail.product_primary_id).addClass("active");
        this.props.sateValChange("view_pro_data", productSlug);
      }
    } else {
      cookie.save("popuptriggerFrom", "FeaturedPro", { path: "/" });
      $.magnificPopup.open({
        items: {
          src: ".order_popup",
        },
        type: "inline",
      });
    }
    return false;
  }

  render() {
    let featureproArr = this.props.featureproduct;
    let featureprolist = [];
    let featureproimagesource = "";
    let tagimagesource = "";

    if (Object.keys(featureproArr).length > 0) {
      if (featureproArr[0].status === "ok") {
        featureprolist = featureproArr[0].result_set;
        featureproimagesource = featureproArr[0].common.image_source;
        tagimagesource = featureproArr[0].common.tag_image_source;
      }
    }

    return (
      <section className="featuredpro-section">
        {Object.keys(featureprolist).length > 0 ? (
          <div className="featured-products-section">
            <div className="container">
              <div className="featured-products-maintitle">
                <h2>Featured Products</h2>
                <p className="featured-products-infotag">
                  We only use the freshest cuts of meat in our briyani, it melts
                  in your mouth when paired with our long-grained basmati rice
                  and delicious mix of masala
                </p>
              </div>
              <div className="featured-products-main">
                <ul
                  className="products-list-ulmain"
                  data-selectedrws="3"
                  data-totalrowcunt={Object.keys(featureprolist).length}
                >
                  {featureprolist.map((featurepro, index) => {
                    var prodivId = "proIndex-" + featurepro.product_primary_id;
                    var comboProId = "comboPro-" + featurepro.product_slug;
                    var hidecelclsname = index > 3 ? "hide-cel-cls" : "";
                    return (
                      <li
                        className={"products-single-li " + hidecelclsname}
                        id={prodivId}
                        key={index}
                      >
                        <div className="products-image-div">
                        {featurepro.product_tag_info != '' && featurepro.product_tag_info != null &&
                        <p className="offer-price">{featurepro.product_tag_info}</p>
                        }
                          {cookie.load("UserId") && (
                            <a
                              className={this.getFavActivCls(featurepro)}
                              href="/"
                              onClick={this.favourActFun.bind(this, featurepro)}
                            >
                              <img
                                className="disbl_href_action favour-heartwhite-icon"
                                src={heartWhite}
                              />
                              <img
                                className="disbl_href_action favour-heartyellow-icon"
                                src={heartYellow}
                              />
                            </a>
                          )}
                          {featurepro.product_thumbnail !== "" ? (
                            <img
                              src={
                                featureproimagesource +
                                "/" +
                                featurepro.product_thumbnail
                              }
                            />
                          ) : (
                            <img src={noimage} />
                          )}
                        </div>

                        <div className="product-info-div">
                          <div className="product-title-maindiv">
                            <div className="product-title">
                              <h3>{stripslashes(featurepro.product_name)}</h3>
                            </div>
                            {Object.keys(featurepro.product_tag).length > 0 ? (
                              <div className="product-tag-list">
                                <ul>
                                  {featurepro.product_tag.map(
                                    (producttag, index1) => {
                                      return (
                                        <li key={index1}>
                                          {producttag.pro_tag_name !== ""
                                            ? producttag.pro_tag_name
                                            : ""}
                                        </li>
                                      );
                                    }
                                  )}
                                </ul>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>

                          <div className="product-short-description">
                            <p>
                              {featurepro.product_short_description != ""
                                ? Parser(
                                    stripslashes(
                                      featurepro.product_short_description
                                    )
                                  )
                                : Parser("&nbsp;")}
                            </p>
                          </div>

                          {this.getProductSplPrice(featurepro)}


                          <div className="products-ordernow-action">
                            <div className="addcart_row addcart_done_maindiv">
                              <div className="qty_bx">
                                <span
                                  className="qty_minus"
                                  onClick={this.proQtyAction.bind(
                                    this,
                                    featurepro.product_primary_id,
                                    "decr"
                                  )}
                                >
                                  -
                                </span>
                                <input
                                  type="text"
                                  className="proqty_input"
                                  readOnly
                                  value="1"
                                />
                                <span
                                  className="qty_plus"
                                  onClick={this.proQtyAction.bind(
                                    this,
                                    featurepro.product_primary_id,
                                    "incr"
                                  )}
                                >
                                  +
                                </span>
                              </div>
                              <button
                                className="btn btn_black order_done"
                                onClick={this.addToCartSimple.bind(
                                  this,
                                  featurepro,
                                  "done"
                                )}
                              >
                                Done
                              </button>
                            </div>

                            {featurepro.product_stock > 0 ||
                            featurepro.product_stock === null ? (
                              featurepro.product_type === "1" ? (
                                <a
                                  className="button order_nowdiv smiple_product_lk disbl_href_action"
                                  href="/"
                                  onClick={this.addToCartSimple.bind(
                                    this,
                                    featurepro,
                                    "initial"
                                  )}
                                >
                                  ADD TO CART
                                </a>
                              ) : (
                                <a
                                  href="/"
                                  onClick={this.viewProDetail.bind(
                                    this,
                                    featurepro
                                  )}
                                  title="Product Details"
                                  id={comboProId}
                                  className="button order_nowdiv compo_product_lk"
                                >
                                  Order Now
                                </a>
                              )
                            ) : (
                              <a
                                className="button disabled disbl_href_action"
                                href="/"
                              >
                                Sold Out
                              </a>
                            )}
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>

                {/* <div className="load-more-btndiv"><a className="button disbl_href_action" onClick={this.loadMoreFunct.bind(this)} href="/">Load more</a></div> */}
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </section>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    featureproduct: state.featureproduct,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getFeatureProList: () => {
      dispatch({ type: GET_FEATUREPRO });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(FeaturedProducts);
