/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import cookie from "react-cookies";
import { connect } from "react-redux";
import {
  appId,
  apiUrl,
  apiUrlV2,
  deliveryId,
  timThumpUrl,
} from "../Helpers/Config";
import {
  getReferenceID,
  stripslashes,
  showPriceValue,
  callImage,
  showLoader,
  hideLoader,
  showCustomAlert,
  showCartLst,
  showAlert,
  removePromoCkValue,
  fbPixelCustomEvent,
} from "../Helpers/SettingHelper";
import noimage from "../../common/images/no-img-product.png";
import heartWhite from "../../common/images/heart-white.png";
import heartYellow from "../../common/images/heart-yellow.png";

import { GET_PRODUCT_LIST } from "../../actions";
import { setMinutes, setHours, getDay, format } from "date-fns";
var Parser = require("html-react-parser");
var qs = require("qs");

class ProductList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedNavigation: "",
      selectedslugType: "",
      productSubcatlist: [],
      productCommon: [],
      productDetails: [],
      filter_Tag: [],
    };
  }

  componentWillReceiveProps(nextProps) {
    let selectedNavigation = nextProps.productState.selectedNavigation;
    let slugType = nextProps.productState.catslugType;
    if (selectedNavigation !== this.state.selectedNavigation) {
      if (selectedNavigation !== "") {
        var catSlug = selectedNavigation;
        var subcatSlug = "";
        if (slugType === "subcategory") {
          catSlug = "";
          subcatSlug = selectedNavigation;
        }
        this.props.getProductList(catSlug, subcatSlug, "");
        $(".addcart_done_maindiv").hide();
        $(".smiple_product_lk").show();
      }
      this.setState({
        selectedNavigation: selectedNavigation,
        selectedslugType: slugType,
      });
    }

    var favTgr = nextProps.productState.favTgr;
    if (favTgr === "Yes") {
      if (selectedNavigation !== "") {
        var catSlug = selectedNavigation;
        var subcatSlug = "";
        if (slugType === "subcategory") {
          catSlug = "";
          subcatSlug = selectedNavigation;
        }
        this.props.getProductList(catSlug, subcatSlug, "");
      }
      nextProps.sateValChange("favTgr", "No");
    }

    if (nextProps.productState.setFiltetTag === "Yes") {
      this.setState(
        { filter_Tag: nextProps.productState.filterTag },
        function () {
          this.productsubcatlist();
          this.props.sateValChange("setFiltetTag", "No");
        }
      );
    }

    if (this.state.productSubcatlist !== nextProps.productSubcatlist) {
      this.setState(
        { productSubcatlist: nextProps.productSubcatlist },
        function () {
          this.productsubcatlist();
        }
      );
    }
  }

  productsubcatlist() {
    var subcatlistArr = this.props.productSubcatlist;
    if (Object.keys(subcatlistArr).length > 0) {
      hideLoader("productlist-main-div", "class");
      const productDetails = subcatlistArr.map((categories, categoryIndex) => {
        if (categories.products.length > 0) {
          return (
            <div className="innerproduct" key={categoryIndex} id={"pro_cat_" + categories.pro_subcate_slug} >
              {categoryIndex === 0 && (
                <div className="header-box mb-40 catry-top-title">
                  <h2 id={categories.pro_cate_slug}>
                    {stripslashes(categories.pro_cate_name)}
                  </h2>
                  {categories.pro_cate_description !== "" && (
                    <p>{stripslashes(categories.pro_cate_description)}</p>
                  )}
                </div>
              )}

              <h3 className="subcat-title" id={categories.pro_subcate_slug}>
                {stripslashes(categories.pro_subcate_name)}
              </h3>
              <ul className="products-list-ulmain">
                {this.productList(
                  categories.products,
                  categories.products.length,
                  categories.pro_subcate_slug
                )}
              </ul>
            </div>
          );
        }
      });
      this.setState({ productDetails: productDetails });
      return productDetails;
    } else {
      return "";
    }
  }

  chkProStockCls(proSlug, Stock) {
    var searchProVal = this.props.productState.searchProVal;
    var actClstxt = searchProVal === proSlug ? " active" : "";

    var returnText =
      "products-single-li no-stock-product " + proSlug + actClstxt;
    if (Stock > 0) {
      returnText = "products-single-li " + proSlug + actClstxt;
    }

    return returnText;
  }

  getProductSplPrice(productDetail) {
    var productSpecialPrice =
      productDetail.product_price_old !== ""
        ? parseFloat(productDetail.product_price_old)
        : 0;
      
    if(productDetail.product_specialprice_applicable === 'yes') {
        return (
          <>
          <div className="product-spl-price ajmir-special-price">
              <h3>{showPriceValue(productDetail.product_price)}</h3>
          </div>
          <div className="product-price">
              <h3>{showPriceValue(productDetail.product_special_price)}</h3>
          </div>
          </>
        );
    }else{
        return (
            <div className="product-price">
              <h3>{showPriceValue(productDetail.product_price)}</h3>
            </div>
        );
      }
  }

  getFavActivCls(product) {
    var favActCls = "disbl_href_action favour-icon-link";
    if (product.fav_product_primary_id === product.product_primary_id) {
      favActCls = favActCls + " active";
    }

    return favActCls;
  }

  favourActFun(productDetail, event) {
    event.preventDefault();
    var productPrimaryId = productDetail.product_primary_id;

    var actionFlgText = "Yes";
    if (
      productDetail.fav_product_primary_id === productDetail.product_primary_id
    ) {
      actionFlgText = "No";
    }

    var postObject = {};
    postObject = {
      app_id: appId,
      outlet_id: cookie.load("orderOutletId"),
      product_id: productPrimaryId,
      fav_flag: actionFlgText,
      availability_id: cookie.load("defaultAvilablityId"),
      customer_id: cookie.load("UserId"),
    };

    var succesFlgTxt =
      actionFlgText === "Yes"
        ? "Great choice! Item added to your favourite list."
        : "Item was removed to your favourite list.";
    var errorFlgTxt =
      actionFlgText === "Yes"
        ? "Sorry! Products can`t add your favourite list."
        : "Sorry! Products can`t remove your favourite list.";

    Axios.post(
      apiUrl + "products/favourite_products_add",
      qs.stringify(postObject)
    ).then((res) => {
      if (res.data.status === "ok") {
        this.handleShowAlertFun("success", succesFlgTxt);
      } else if (res.data.status === "error") {
        var errMsgtxt =
          res.data.message !== "" ? res.data.message : errorFlgTxt;
        this.handleShowAlertFun("Error", errMsgtxt);
      }
      this.props.sateValChange("favTgr", "Yes");
      return false;
    });
  }

  /* Products List */
  productList(list, productcount, pro_subcate_slug) {
    var imageSource = this.props.productCommon.product_image_source;
    var tagImageSource = this.props.productCommon.tag_image_source;
    var slugType = this.state.selectedslugType;
    var naviSlug = this.state.selectedNavigation;
    var totalProductShow = 0;
    var listProduct = list.map((productDetail, index) => {
      var description =
        productDetail.product_short_description !== null &&
        productDetail.product_short_description !== ""
          ? Parser(stripslashes(productDetail.product_short_description))
          : "";

      var prodivId = "proIndex-" + productDetail.product_primary_id;
      var comboProId = "comboPro-" + productDetail.product_slug;
      let filter = this.checkFilterTag(productDetail.product_tag);
      if (filter === 1) {
        totalProductShow++;
        return (
          <li
            className={this.chkProStockCls(
              productDetail.product_slug,
              productDetail.product_stock
            )}
            id={prodivId}
            key={index}
          >
            <div className="products-image-div">
            {productDetail.product_tag_info != '' && productDetail.product_tag_info != null &&
            <p className="offer-price">{productDetail.product_tag_info}</p>
            }
              {cookie.load("UserId") && (
                <a
                  className={this.getFavActivCls(productDetail)}
                  href="/"
                  onClick={this.favourActFun.bind(this, productDetail)}
                >
                  <img
                    className="disbl_href_action favour-heartwhite-icon"
                    src={heartWhite}
                  />
                  <img
                    className="disbl_href_action favour-heartyellow-icon"
                    src={heartYellow}
                  />
                </a>
              )}
              {productDetail.product_thumbnail !== "" ? (
                <img onClick={this.viewProDetail.bind(this, productDetail)}
                  src={imageSource + "/" + productDetail.product_thumbnail}
                />
              ) : (
                <img onClick={this.viewProDetail.bind(this, productDetail)} src={noimage} />
              )}
            </div>

            <div className="product-info-div">
              <div className="product-title-maindiv">
                <div className="product-title">
                  <h5>
                    {productDetail.product_alias !== ""
                      ? stripslashes(productDetail.product_alias)
                      : stripslashes(productDetail.product_name)}
                  </h5>
                </div>
                <div className="product-tag-list">
                  {Object.keys(productDetail.product_tag).length > 0 ? (
                    <ul>
                      {productDetail.product_tag.map((producttag, index1) => {
                        return (
                          <li key={index1}>
                            {producttag.pro_tag_name !== ""
                              ? producttag.pro_tag_name
                              : ""}
                          </li>
                        );
                      })}
                    </ul>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="product-short-description">
                <p>{description !== "" ? description : Parser("&nbsp;")}</p>
              </div>

              {this.getProductSplPrice(productDetail)}


              <div className="products-ordernow-action">
                {productDetail.pro_current_datetime_avl === "yes" ? (
                  productDetail.product_stock > 0 ||
                  productDetail.product_stock === null ? (
                    productDetail.product_type === "1" ||
                    productDetail.product_type === "5" ? (
                      <a
                        href="/"
                        onClick={this.viewProDetail.bind(this, productDetail)}
                        title="Product Details"
                        id={comboProId}
                        className="button order_nowdiv compo_product_lk"
                      >
                        Order Now
                      </a>
                    ) : (
                      <a
                        href="/"
                        onClick={this.viewProDetail.bind(this, productDetail)}
                        title="Product Details"
                        id={comboProId}
                        className="button order_nowdiv compo_product_lk"
                      >
                        Order Now
                      </a>
                    )
                  ) : (
                    <a
                      className="button order_nowdiv disabled disbl_href_action"
                      href="/"
                    >
                      Sold Out
                    </a>
                  )
                ) : (
                  <a
                    className="button order_nowdiv disabled disbl_href_action"
                    href="/"
                  >
                    Sold Out
                  </a>
                )}

                <div className="addcart_row addcart_done_maindiv">
                  <div className="addcart-row-child">
                    <div className="qty_bx">
                      <span
                        className="qty_minus"
                        onClick={this.proQtyAction.bind(
                          this,
                          productDetail.product_primary_id,
                          "decr"
                        )}
                      >
                        -
                      </span>
                      <input
                        type="text"
                        className="proqty_input"
                        readOnly
                        value="1"
                      />
                      <span
                        className="qty_plus product_plus"
                        onClick={this.proQtyAction.bind(
                          this,
                          productDetail.product_primary_id,
                          "incr"
                        )}
                      >
                        +
                      </span>
                    </div>
                    <button
                      className="btn btn_black"
                      onClick={this.addToCartSimple.bind(
                        this,
                        productDetail,
                        "done"
                      )}
                    >
                      Done
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </li>
        );
      }
    });
    if (totalProductShow > 0) {
      $("#" + pro_subcate_slug).show();
      return listProduct;
    } else {
      $("#" + pro_subcate_slug).hide();
      listProduct = (
        <div>
          <h3>No Product Found</h3>
        </div>
      );
      return listProduct;
    }
  }

  checkFilterTag(productFilter) {
    var current = this;
    if (
      this.state.filter_Tag !== "" &&
      typeof this.state.filter_Tag !== undefined &&
      typeof this.state.filter_Tag !== "undefined" &&
      this.state.filter_Tag.length > 0
    ) {
      if (Object.keys(productFilter).length > 0) {
        let tagAvil = 0;
        productFilter.map(function (item) {
          if (current.state.filter_Tag.indexOf(item.tag_id) >= 0) {
            tagAvil++;
          }
        });
        if (tagAvil > 0) {
          return 1;
        } else {
          return 0;
        }
      }
    } else {
      return 1;
    }
  }

  proQtyAction(indxFlg, actionFlg) {
    var proqtyInput = $("#proIndex-" + indxFlg)
      .find(".proqty_input")
      .val();
    proqtyInput = parseInt(proqtyInput);
    if (actionFlg === "decr") {
      proqtyInput = proqtyInput > 1 ? proqtyInput - 1 : proqtyInput;
    } else {
      proqtyInput = proqtyInput + 1;
    }
    $("#proIndex-" + indxFlg)
      .find(".proqty_input")
      .val(proqtyInput);
  }

  /* add to cart */
  addToCartSimple(productDetail, actionFlg, event) {
    event.preventDefault();
    var IndexFlg = productDetail.product_primary_id;

    var orderOutletId = cookie.load("orderOutletId");
    if (orderOutletId === "" || orderOutletId === undefined){
      $.magnificPopup.open({
        items: {
          src: "#order-popup",
        },
        type: "inline",
      });
      return false;
    }

    if (actionFlg === "initial") {
      $("#proIndex-" + IndexFlg).addClass("active");
      $("#proIndex-" + IndexFlg)
        .find(".smiple_product_lk")
        .hide();
      $("#proIndex-" + IndexFlg)
        .find(".addcart_done_maindiv")
        .show();
      return false;
    } else {
      showLoader("proIndex-" + IndexFlg, "Idtext");
      var availabilityId = cookie.load("defaultAvilablityId");
      /*var availabilityId = deliveryId;*/
      var availabilityName =
        availabilityId === deliveryId ? "Delivery" : "Pickup";
      var isAddonProduct = "No";
      var productId = productDetail.product_id;
      var customerId =
        typeof cookie.load("UserId") === "undefined"
          ? ""
          : cookie.load("UserId");
      var proqtyQty = $("#proIndex-" + IndexFlg)
        .find(".proqty_input")
        .val();
    
      var specialprice_applicable = productDetail.product_specialprice_applicable;

      var postObject = {};
      postObject = {
        app_id: appId,
        product_id: productId,
        product_qty: proqtyQty,
        product_type: 1,
        availability_id: availabilityId,
        availability_name: availabilityName,
        isAddonProduct: isAddonProduct,
        reference_id: customerId === "" ? getReferenceID() : "",
        customer_id: customerId,
        specialprice_applicable: specialprice_applicable,
      };

      Axios.post(
        apiUrlV2 + "cart/simpleCartInsert",
        qs.stringify(postObject)
      ).then((res) => {
        $("#proIndex-" + IndexFlg).removeClass("active");
        hideLoader("proIndex-" + IndexFlg, "Idtext");
        $("#proIndex-" + IndexFlg)
          .find(".addcart_done_maindiv")
          .hide();
        $("#proIndex-" + IndexFlg)
          .find(".smiple_product_lk")
          .show();
        if (res.data.status === "ok") {
          this.setState({compoinput_value: 1});
          this.props.sateValChange("cartflg", "yes");
          removePromoCkValue();
          // showCustomAlert("success", "Great choice! Item added to your cart.");
          /*showCartLst();*/
          this.handleShowAlertFun(
            "success",
            "Great choice! Item added to your cart."
          );
          fbPixelCustomEvent('AddToCartSuccess',{});
        } else if (res.data.status === "error") {
          this.setState({compoinput_value: 1});
          var errMsgtxt =
            res.data.message !== ""
              ? res.data.message
              : "Sorry! Products can`t add your cart.";
          showCustomAlert("error", errMsgtxt);
          this.handleShowAlertFun("Error", errMsgtxt);
        }

        return false;
      });
    }
  }

  viewProDetail(productDetail, event) {
    event.preventDefault();

    var orderOutletId = cookie.load("orderOutletId");
    if (orderOutletId === "" || orderOutletId === undefined){
      $.magnificPopup.open({
        items: {
          src: "#order-popup",
        },
        type: "inline",
      });
      return false;
    }
    
    this.setState({ product_remarks: "" }, () => 
    console.log(this.state.product_remarks));
    setTimeout(function(){
    $(document).find('#special_notes').val('');
    },1000)
    var productSlug = productDetail.product_slug;
    if (productSlug !== "") {
      $("#proIndex-" + productDetail.product_primary_id).addClass("active");
      this.props.sateValChange("view_pro_data", productSlug);
    }
    return false;
  }

  handleShowAlertFun(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  render() {
    return (
      <div className="productlist-main-div">
        <div className="innerproduct_row">{this.state.productDetails}</div>
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  var tempArr = Array();
  var productSubcat = Array();
  var productCmn = Array();
  if (Object.keys(state.product).length > 0) {
    var tempArr = !("productlist" in state.product[0])
      ? Array()
      : state.product[0].productlist;
    if (Object.keys(tempArr).length > 0) {
      if (tempArr[0].status === "ok") {
        productSubcat = tempArr[0].result_set[0].subcategorie;
        productCmn = tempArr[0].common;
      }
    }
  }

  return {
    productSubcatlist: productSubcat,
    productCommon: productCmn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProductList: (catslug, subcatslug, outletid) => {
      dispatch({ type: GET_PRODUCT_LIST, catslug, subcatslug, outletid });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(ProductList);
