/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Axios from "axios";
import cookie from "react-cookies";
import {
  appId,
  apiUrl,
  apiUrlV2,
  deliveryId,
  timThumpUrl,
} from "../../Helpers/Config";
import {
  getReferenceID,
  stripslashes,
  showPriceValue,
  callImage,
  showLoader,
  hideLoader,
  showCustomAlert,
  showCartLst,
  showAlert,
  removePromoCkValue,
} from "../../Helpers/SettingHelper";
import noimage from "../../../common/images/no-img-product.png";
import promoNoimage from "../../../common/images/noimg-716X674.jpg";
import heartWhite from "../../../common/images/heart-white.png";
import heartYellow from "../../../common/images/heart-yellow.png";
import { setMinutes, setHours, getDay, format } from "date-fns";
var qs = require("qs");

import { GET_PROMOTIONPRO } from "../../../actions";
import OwlCarousel from "react-owl-carousel2";

var Parser = require("html-react-parser");

const options = {
  items: 3,
  autoplay: false,
  autoWidth: true,
  margin: 15,
  loop: true,
};

class PromotionProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
    };
  }

  componentWillMount() {
    this.props.getPromotionProList();
  }

  proQtyAction(indxFlg, actionFlg) {
    var proqtyInput = $("#proIndex-" + indxFlg)
      .find(".proqty_input")
      .val();
    proqtyInput = parseInt(proqtyInput);
    if (actionFlg === "decr") {
      proqtyInput = proqtyInput > 1 ? proqtyInput - 1 : proqtyInput;
    } else {
      proqtyInput = proqtyInput + 1;
    }
    $("#proIndex-" + indxFlg)
      .find(".proqty_input")
      .val(proqtyInput);
  }

  /* add to cart */
  addToCartSimple(productDetail, actionFlg, event) {
    event.preventDefault();
    var avilablityId = cookie.load("defaultAvilablityId");
    var orderOutletId = cookie.load("orderOutletId");
    if (
      avilablityId !== "" &&
      orderOutletId != undefined &&
      orderOutletId !== "" &&
      orderOutletId != undefined
    ) {
      var IndexFlg = productDetail.product_primary_id;

      if (actionFlg === "initial") {
        $("#proIndex-" + IndexFlg).addClass("active");
        $("#proIndex-" + IndexFlg)
          .find(".smiple_product_lk")
          .hide();
        $("#proIndex-" + IndexFlg)
          .find(".addcart_done_maindiv")
          .show();
      } else {
        showLoader("proIndex-" + IndexFlg, "Idtext");
        var availabilityId = cookie.load("defaultAvilablityId");
        var availabilityName =
          availabilityId === deliveryId ? "Delivery" : "Pickup";
        var isAddonProduct = "No";
        var productId = productDetail.product_id;
        var customerId =
          typeof cookie.load("UserId") === "undefined"
            ? ""
            : cookie.load("UserId");
        var proqtyQty = $("#proIndex-" + IndexFlg)
          .find(".proqty_input")
          .val();

        var postObject = {};
        postObject = {
          app_id: appId,
          product_id: productId,
          product_qty: proqtyQty,
          product_type: 1,
          availability_id: availabilityId,
          availability_name: availabilityName,
          isAddonProduct: isAddonProduct,
          reference_id: customerId === "" ? getReferenceID() : "",
          customer_id: customerId,
        };

        Axios.post(
          apiUrlV2 + "cart/simpleCartInsert",
          qs.stringify(postObject)
        ).then((res) => {
          $("#proIndex-" + IndexFlg).removeClass("active");
          hideLoader("proIndex-" + IndexFlg, "Idtext");
          $("#proIndex-" + IndexFlg)
            .find(".addcart_done_maindiv")
            .hide();
          $("#proIndex-" + IndexFlg)
            .find(".smiple_product_lk")
            .show();
          if (res.data.status === "ok") {
            this.setState({compoinput_value: 1});
            this.props.sateValChange("cartflg", "yes");
            showCustomAlert(
              "success",
              "Great choice! Item added to your cart."
            );
            /*showCartLst();*/
            removePromoCkValue();
            this.handleShowAlertFun(
              "success",
              "Great choice! Item added to your cart."
            );
            return false;
          } else if (res.data.status === "error") {
            this.setState({compoinput_value: 1});
            var errMsgtxt =
              res.data.message !== ""
                ? res.data.message
                : "Sorry! Products can`t add your cart.";
            showCustomAlert("error", errMsgtxt);
          }
        });
      }
    } else {
      cookie.save("popuptriggerFrom", "PromotionPro", { path: "/" });
      $.magnificPopup.open({
        items: {
          src: ".order_popup",
        },
        type: "inline",
      });
    }
  }

  handleShowAlertFun(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  getProductSplPrice(productDetail) {
    var productSpecialPrice =
      productDetail.product_price_old !== ""
        ? parseFloat(productDetail.product_price_old)
        : 0;
    if (
      productSpecialPrice > 0
    ) {
        return (
          <>
          <div className="product-spl-price">
              <h3>{showPriceValue(productDetail.product_price_old)}</h3>
          </div>
          <div className="product-price">
              <h3>{showPriceValue(productDetail.product_price)}</h3>
          </div>
          </>
        );
    }else{
        return (
            <div className="product-price">
              <h3>{showPriceValue(productDetail.product_price)}</h3>
            </div>
        );
      }
  }

  getFavActivCls(product) {
    var favActCls = "disbl_href_action favour-icon-link";
    if (product.fav_product_primary_id === product.product_primary_id) {
      favActCls = favActCls + " active";
    }

    return favActCls;
  }

  favourActFun(productDetail, event) {
    event.preventDefault();
    var productPrimaryId = productDetail.product_primary_id;

    var actionFlgText = "Yes";
    if (
      productDetail.fav_product_primary_id === productDetail.product_primary_id
    ) {
      actionFlgText = "No";
    }

    var postObject = {};
    postObject = {
      app_id: appId,
      outlet_id: cookie.load("orderOutletId"),
      product_id: productPrimaryId,
      fav_flag: actionFlgText,
      availability_id: cookie.load("defaultAvilablityId"),
      customer_id: cookie.load("UserId"),
    };

    var succesFlgTxt =
      actionFlgText === "Yes"
        ? "Great choice! Item added to your favourite list."
        : "Item was removed to your favourite list.";
    var errorFlgTxt =
      actionFlgText === "Yes"
        ? "Sorry! Products can`t add your favourite list."
        : "Sorry! Products can`t remove your favourite list.";

    Axios.post(
      apiUrl + "products/favourite_products_add",
      qs.stringify(postObject)
    ).then((res) => {
      if (res.data.status === "ok") {
        this.setState({compoinput_value: 1});
        this.handleShowAlertFun("success", succesFlgTxt);
      } else if (res.data.status === "error") {
        this.setState({compoinput_value: 1});
        var errMsgtxt =
          res.data.message !== "" ? res.data.message : errorFlgTxt;
        this.handleShowAlertFun("Error", errMsgtxt);
      }
      this.props.sateValChange("favTgr", "Yes");
      return false;
    });
  }

  loadMoreFunct(event) {
    event.preventDefault();
    var selectedrwsVal = $(".products-list-ulmain").attr("data-selectedrws");
    var totalrowcuntVal = $(".products-list-ulmain").attr("data-totalrowcunt");

    selectedrwsVal = parseInt(selectedrwsVal) + 4;

    $(".products-list-ulmain").attr("data-selectedrws", selectedrwsVal);
    console.log("selectedrwsVal", selectedrwsVal);
    $(".products-single-li").each(function (index) {
      if (index <= selectedrwsVal) {
        $(this).removeClass("hide-cel-cls");
      }
    });
  }

  viewProDetail(productDetail, event) {
    event.preventDefault();
    var avilablityId = cookie.load("defaultAvilablityId");
    var orderOutletId = cookie.load("orderOutletId");
    if (
      avilablityId !== "" &&
      orderOutletId != undefined &&
      orderOutletId !== "" &&
      orderOutletId != undefined
    ) {
      var productSlug = productDetail.product_slug;
      if (productSlug !== "") {
        $("#proIndex-" + productDetail.product_primary_id).addClass("active");
        this.props.sateValChange("view_pro_data", productSlug);
      }
    } else {
      cookie.save("popuptriggerFrom", "PromotionPro", { path: "/" });
      $.magnificPopup.open({
        items: {
          src: ".order_popup",
        },
        type: "inline",
      });
    }
    return false;
  }

  render() {
    let promotionproArr = this.props.promotionproduct;
    let promotionprolist = [];
    let promotionproimagesource = "";
    let tagimagesource = "";

    if (Object.keys(promotionproArr).length > 0) {
      if (promotionproArr[0].status === "ok") {
        promotionprolist = promotionproArr[0].result_set;
        promotionproimagesource = promotionproArr[0].common.image_source;
        tagimagesource = promotionproArr[0].common.tag_image_source;
      }
    }

    return (
      <section className="home-promotions">
        <div className="container">
          <h2>Our Promotions</h2>
          <p>
            Look out for our weekly special discounts that will be highlighted
            below
          </p>
          <div className="hp-parent">
            <OwlCarousel options={options}>
              {promotionprolist.map((promotionpro, index) => {
                var prodivId = "proIndex-" + promotionpro.product_primary_id;
                var comboProId = "comboPro-" + promotionpro.product_slug;
                var hidecelclsname = index > 3 ? "hide-cel-cls" : "";
                if (index === 0) {
                  return (
                    <div className="promobig-lhs" key={index}>
                      {promotionpro.product_thumbnail !== "" ? (
                        <img
                          src={
                            promotionproimagesource +
                            "/" +
                            promotionpro.product_thumbnail
                          }
                        />
                      ) : (
                        <img src={promoNoimage} />
                      )}
                      <div className="promobig-content">
                        <h4>{stripslashes(promotionpro.product_name)}</h4>
                        <p>
                          {promotionpro.product_short_description != ""
                            ? Parser(
                                stripslashes(
                                  promotionpro.product_short_description
                                )
                              )
                            : Parser("&nbsp;")}
                        </p>

                        <div className="products-ordernow-action">
                          <div className="addcart_row addcart_done_maindiv">
                            <div className="qty_bx">
                              <span
                                className="qty_minus"
                                onClick={this.proQtyAction.bind(
                                  this,
                                  promotionpro.product_primary_id,
                                  "decr"
                                )}
                              >
                                {" "}
                                -{" "}
                              </span>
                              <input
                                type="text"
                                className="proqty_input"
                                readOnly
                                value="1"
                              />
                              <span
                                className="qty_plus"
                                onClick={this.proQtyAction.bind(
                                  this,
                                  promotionpro.product_primary_id,
                                  "incr"
                                )}
                              >
                                {" "}
                                +{" "}
                              </span>
                            </div>

                            <button
                              className="btn btn_black order_done"
                              onClick={this.addToCartSimple.bind(
                                this,
                                promotionpro,
                                "done"
                              )}
                            >
                              Done
                            </button>
                          </div>

                          {promotionpro.product_stock > 0 ||
                          promotionpro.product_stock === null ? (
                            promotionpro.product_type === "11111" ? (
                              <a
                                className="button order_nowdiv smiple_product_lk disbl_href_action"
                                href="/"
                                onClick={this.addToCartSimple.bind(
                                  this,
                                  promotionpro,
                                  "initial"
                                )}
                              >
                                ADD TO CART
                              </a>
                            ) : (
                              <a
                                href="/"
                                onClick={this.viewProDetail.bind(
                                  this,
                                  promotionpro
                                )}
                                title="Product Details"
                                id={comboProId}
                                className="button order_nowdiv compo_product_lk"
                              >
                                Order Now
                              </a>
                            )
                          ) : (
                            <a
                              className="button disabled disbl_href_action"
                              href="/"
                            >
                              Sold Out
                            </a>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div key={index}>
                      <div className="promo-main-div">
                        <div className="products-image-div">
                        {promotionpro.product_tag_info != '' && promotionpro.product_tag_info != null &&
                        <p className="offer-price">{promotionpro.product_tag_info}</p>
                        }
                          {promotionpro.product_thumbnail !== "" ? (
                            <img
                              src={
                                promotionproimagesource +
                                "/" +
                                promotionpro.product_thumbnail
                              }
                            />
                          ) : (
                            <img src={noimage} />
                          )}
                        </div>
                        <div className="product-info-div">
                          <div className="product-title-maindiv">
                            <div className="product-title">
                              <h3>{stripslashes(promotionpro.product_name)}</h3>
                            </div>

                            {Object.keys(promotionpro.product_tag).length >
                            0 ? (
                              <div className="product-tag-list">
                                <ul>
                                  {promotionpro.product_tag.map(
                                    (producttag, index1) => {
                                      return (
                                        <li key={index1}>
                                          {producttag.pro_tag_name !== ""
                                            ? producttag.pro_tag_name
                                            : ""}
                                        </li>
                                      );
                                    }
                                  )}
                                </ul>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>

                          <div className="product-short-description">
                            <p>
                              {promotionpro.product_short_description != ""
                                ? Parser(
                                    stripslashes(
                                      promotionpro.product_short_description
                                    )
                                  )
                                : Parser("&nbsp;")}
                            </p>
                          </div>

                          {this.getProductSplPrice(promotionpro)}


                          <div className="products-ordernow-action">
                            <div className="addcart_row addcart_done_maindiv">
                              <div className="qty_bx">
                                <span
                                  className="qty_minus"
                                  onClick={this.proQtyAction.bind(
                                    this,
                                    promotionpro.product_primary_id,
                                    "decr"
                                  )}
                                >
                                  {" "}
                                  -{" "}
                                </span>
                                <input
                                  type="text"
                                  className="proqty_input"
                                  readOnly
                                  value="1"
                                />
                                <span
                                  className="qty_plus"
                                  onClick={this.proQtyAction.bind(
                                    this,
                                    promotionpro.product_primary_id,
                                    "incr"
                                  )}
                                >
                                  {" "}
                                  +{" "}
                                </span>
                              </div>

                              <button
                                className="btn btn_black order_done"
                                onClick={this.addToCartSimple.bind(
                                  this,
                                  promotionpro,
                                  "done"
                                )}
                              >
                                Done
                              </button>
                            </div>

                            {promotionpro.product_stock > 0 ||
                            promotionpro.product_stock === null ? (
                              promotionpro.product_type === "1111" ? (
                                <a
                                  className="button order_nowdiv smiple_product_lk disbl_href_action"
                                  href="/"
                                  onClick={this.addToCartSimple.bind(
                                    this,
                                    promotionpro,
                                    "initial"
                                  )}
                                >
                                  ADD TO CART
                                </a>
                              ) : (
                                <a
                                  href="/"
                                  onClick={this.viewProDetail.bind(
                                    this,
                                    promotionpro
                                  )}
                                  title="Product Details"
                                  id={comboProId}
                                  className="button order_nowdiv compo_product_lk"
                                >
                                  ADD TO CART
                                </a>
                              )
                            ) : (
                              <a
                                className="button disabled disbl_href_action"
                                href="/"
                              >
                                Sold Out
                              </a>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
            </OwlCarousel>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    promotionproduct: state.promotionproduct,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPromotionProList: () => {
      dispatch({ type: GET_PROMOTIONPRO });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(PromotionProducts);
